<div class="uk-section uk-section-primary uk-preserve-color footerSection uk-section-small">
  <div class="uk-container uk-container-small uk-container-center">

    <div class="uk-grid">

      <div class="uk-width-1-1@m uk-width-1-1@l uk-width-1-1@s uk-margin">
        <div class="uk-flex">
          <span class="uk-margin-right footerImage">
            <img src="../../../assets/images/EN%20V%20Funded%20by%20the%20EU_POS.png" width="130">
          </span>
          <span class="uk-margin-small-top">EOSC Observatory receives funding from the European Union’s Horizon 2020 Research and Innovation
            programme under EOSC Future (No. 101017536).</span>
        </div>

        <!--MOBILE & PAD PORTRAIT-->
<!--        <div class="uk-hidden@m">-->
<!--          <div class="uk-margin-top">-->
<!--            <a class="footerLink uk-display-block">Terms of use & privacy policy</a>-->
<!--            &lt;!&ndash;<a class="footerLink uk-display-block uk-margin-small-top">Sources and methodology</a>&ndash;&gt;-->
<!--          </div>-->

<!--          <div class="uk-margin-medium-top uk-text-left@s uk-text-center">-->
<!--            <div class="uk-child-width-auto uk-grid-small uk-flex-left@s uk-flex-center uk-grid" uk-grid="">-->
<!--              <div class="uk-first-column">-->
<!--                <a href="http://www.facebook.com/groups/openaire/" target="_blank" class="el-link uk-icon-button uk-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path>-->
<!--                  </svg>-->
<!--                </a>-->
<!--              </div>-->
<!--              <div>-->
<!--                <a href="http://www.twitter.com/OpenAIRE_eu" target="_blank" class="el-link uk-icon-button uk-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M19,4.74 C18.339,5.029 17.626,5.229 16.881,5.32 C17.644,4.86 18.227,4.139 18.503,3.28 C17.79,3.7 17.001,4.009 16.159,4.17 C15.485,3.45 14.526,3 13.464,3 C11.423,3 9.771,4.66 9.771,6.7 C9.771,6.99 9.804,7.269 9.868,7.539 C6.795,7.38 4.076,5.919 2.254,3.679 C1.936,4.219 1.754,4.86 1.754,5.539 C1.754,6.82 2.405,7.95 3.397,8.61 C2.79,8.589 2.22,8.429 1.723,8.149 L1.723,8.189 C1.723,9.978 2.997,11.478 4.686,11.82 C4.376,11.899 4.049,11.939 3.713,11.939 C3.475,11.939 3.245,11.919 3.018,11.88 C3.49,13.349 4.852,14.419 6.469,14.449 C5.205,15.429 3.612,16.019 1.882,16.019 C1.583,16.019 1.29,16.009 1,15.969 C2.635,17.019 4.576,17.629 6.662,17.629 C13.454,17.629 17.17,12 17.17,7.129 C17.17,6.969 17.166,6.809 17.157,6.649 C17.879,6.129 18.504,5.478 19,4.74"></path>-->
<!--                  </svg>-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="">-->
<!--                <a href="http://www.linkedin.com/groups/OpenAIRE-3893548" target="_blank" class="el-link uk-icon-button uk-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>-->
<!--                    <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>-->
<!--                  </svg>-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="">-->
<!--                <a href="https://www.youtube.com/channel/UChFYqizc-S6asNjQSoWuwjw" target="_blank" class="el-link uk-icon-button uk-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M15,4.1c1,0.1,2.3,0,3,0.8c0.8,0.8,0.9,2.1,0.9,3.1C19,9.2,19,10.9,19,12c-0.1,1.1,0,2.4-0.5,3.4c-0.5,1.1-1.4,1.5-2.5,1.6 c-1.2,0.1-8.6,0.1-11,0c-1.1-0.1-2.4-0.1-3.2-1c-0.7-0.8-0.7-2-0.8-3C1,11.8,1,10.1,1,8.9c0-1.1,0-2.4,0.5-3.4C2,4.5,3,4.3,4.1,4.2 C5.3,4.1,12.6,4,15,4.1z M8,7.5v6l5.5-3L8,7.5z"></path>-->
<!--                  </svg>-->
<!--                </a>-->
<!--              </div>-->

<!--            </div>-->
<!--            <div class="uk-margin-medium-top">-->
<!--              <h5 class="el-title uk-margin-medium-right uk-h5 uk-inline uk-margin-remove">Newsletter</h5>-->
<!--              <a class="el-link footerLink" href="/newsletter/listing">-->
<!--                <span class="el-image uk-icon" uk-icon="icon: rss; ratio: 1;">-->
<!--                </span>-->
<!--              </a>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!--LAPTOP & PAD LANDSCAPE-->
<!--        <div class="uk-visible@m">-->
<!--          <div class="uk-margin-top">-->
<!--            <a class="footerLink uk-margin-medium-right">Terms of use & privacy policy</a>-->
<!--            &lt;!&ndash;<a class="footerLink">Sources and methodology</a>&ndash;&gt;-->
<!--          </div>-->

<!--          <div class="uk-margin-top uk-text-left@s uk-text-center">-->
<!--            <div class="uk-child-width-auto uk-grid-small uk-flex-left@s uk-flex-center uk-grid" uk-grid="">-->
<!--              <div class="uk-first-column">-->
<!--                <a href="http://www.facebook.com/groups/openaire/" target="_blank" class="el-link uk-icon-button uk-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"></path>-->
<!--                  </svg>-->
<!--                </a>-->
<!--              </div>-->
<!--              <div>-->
<!--                <a href="http://www.twitter.com/OpenAIRE_eu" target="_blank" class="el-link uk-icon-button uk-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M19,4.74 C18.339,5.029 17.626,5.229 16.881,5.32 C17.644,4.86 18.227,4.139 18.503,3.28 C17.79,3.7 17.001,4.009 16.159,4.17 C15.485,3.45 14.526,3 13.464,3 C11.423,3 9.771,4.66 9.771,6.7 C9.771,6.99 9.804,7.269 9.868,7.539 C6.795,7.38 4.076,5.919 2.254,3.679 C1.936,4.219 1.754,4.86 1.754,5.539 C1.754,6.82 2.405,7.95 3.397,8.61 C2.79,8.589 2.22,8.429 1.723,8.149 L1.723,8.189 C1.723,9.978 2.997,11.478 4.686,11.82 C4.376,11.899 4.049,11.939 3.713,11.939 C3.475,11.939 3.245,11.919 3.018,11.88 C3.49,13.349 4.852,14.419 6.469,14.449 C5.205,15.429 3.612,16.019 1.882,16.019 C1.583,16.019 1.29,16.009 1,15.969 C2.635,17.019 4.576,17.629 6.662,17.629 C13.454,17.629 17.17,12 17.17,7.129 C17.17,6.969 17.166,6.809 17.157,6.649 C17.879,6.129 18.504,5.478 19,4.74"></path>-->
<!--                  </svg>-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="">-->
<!--                <a href="http://www.linkedin.com/groups/OpenAIRE-3893548" target="_blank" class="el-link uk-icon-button uk-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path>-->
<!--                    <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path>-->
<!--                  </svg>-->
<!--                </a>-->
<!--              </div>-->
<!--              <div class="">-->
<!--                <a href="https://www.youtube.com/channel/UChFYqizc-S6asNjQSoWuwjw" target="_blank" class="el-link uk-icon-button uk-icon">-->
<!--                  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M15,4.1c1,0.1,2.3,0,3,0.8c0.8,0.8,0.9,2.1,0.9,3.1C19,9.2,19,10.9,19,12c-0.1,1.1,0,2.4-0.5,3.4c-0.5,1.1-1.4,1.5-2.5,1.6 c-1.2,0.1-8.6,0.1-11,0c-1.1-0.1-2.4-0.1-3.2-1c-0.7-0.8-0.7-2-0.8-3C1,11.8,1,10.1,1,8.9c0-1.1,0-2.4,0.5-3.4C2,4.5,3,4.3,4.1,4.2 C5.3,4.1,12.6,4,15,4.1z M8,7.5v6l5.5-3L8,7.5z"></path>-->
<!--                  </svg>-->
<!--                </a>-->
<!--              </div>-->

<!--              <div class="uk-margin-medium-left" style="margin-top: 7px;">-->
<!--                <h5 class="el-title uk-margin-medium-right uk-h5 uk-inline uk-margin-remove">Newsletter</h5>-->
<!--                <a class="el-link footerLink" href="/newsletter/listing">-->
<!--                <span class="el-image uk-icon" uk-icon="icon: rss; ratio: 1;">-->
<!--                </span>-->
<!--                </a>-->

<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


      </div>

    </div>

<!--    <div class="uk-grid" uk-grid="">-->
<!--      <div class="uk-width-expand@m uk-width-expand@l uk-width-expand@s">-->
<!--        <div id="footer#22" class=" uk-text-small uk-margin uk-margin-remove-bottom uk-text-center@m uk-text-center uk-text-lead">-->
<!--          <a href="http://creativecommons.org/licenses/by/4.0/" rel="license">-->
<!--            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" class=" uk-svg uk-margin-small-right">-->
<!--              <title></title>-->
<!--              <g data-name="Creative Commons" id="Creative_Commons">-->
<!--                <circle cx="12" cy="12" r="11.5" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round"></circle>-->
<!--                <path d="M10.87,10a3.5,3.5,0,1,0,0,4" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round"></path>-->
<!--                <path d="M18.87,10a3.5,3.5,0,1,0,0,4" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round"></path>-->
<!--              </g>-->
<!--            </svg>-->
<!--            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24" class=" uk-svg">-->
<!--              <title></title>-->
<!--              <g id="Attribution">-->
<!--                <g data-name="<Group>" id="_Group_">-->
<!--                  <circle cx="12" cy="5" data-name="<Path>" id="_Path_" r="1.5" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round"></circle>-->
<!--                  <path d="M12,8a5,5,0,0,0-3.07.71,1,1,0,0,0-.43.83V15H10v5.5h4V15h1.5V9.54a1,1,0,0,0-.43-.83A5,5,0,0,0,12,8Z" data-name="<Path>" id="_Path_2" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round"></path>-->
<!--                </g>-->
<!--                <circle cx="12" cy="12" r="11.5" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round"></circle>-->
<!--              </g>-->
<!--            </svg>-->
<!--          </a>-->
<!--          &nbsp;Unless otherwise indicated, all materials created by OpenAIRE are licenced under&nbsp;<a href="http://creativecommons.org/licenses/by/4.0/" rel="license" class="footerLink">CC ATTRIBUTION 4.0 INTERNATIONAL LICENSE</a>.-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
