<div class="uk-section-default uk-section uk-section-xsmall uk-padding-remove-bottom">
  <div class="uk-container uk-container-expand">
    <div class="uk-grid">

      <app-side-menu class="uk-width-1-5" [chapterModel]="formBuilder" (showChapterOrSection)="updateView($event)"></app-side-menu>
      <div class="uk-width-3-5">
        <ul id="chapter-tabs" class="uk-switcher">
          <li *ngFor="let chapter of formBuilder">
            <app-chapter-builder [chapter]="chapter" [ngClass]="{'uk-display-none': show !== 'chapter'}"></app-chapter-builder>
            <ul id="section-tabs" class="uk-switcher" [ngClass]="{'uk-display-none': show !== 'section'}">
              <li *ngFor="let section of chapter.subSections">
                <app-section-builder [section]="section"></app-section-builder>
                <ng-container *ngFor="let field of section.fields; let i = index">
                  <app-field-builder [field]="field"></app-field-builder>
                </ng-container>
              </li>
            </ul>
          </li>
        </ul>
      </div>

    </div>
  </div>
</div>

<pre>{{formBuilder | json}}</pre>
