  <form [formGroup]="form" class="uk-margin-small-bottom {{fieldData.form.display.cssClasses}}" [ngClass]="{'uk-hidden clear-style': hideField}" >
  <div *ngIf="fieldData.label.text" class="uk-form-label uk-margin-bottom {{fieldData.label.cssClasses}}"
       [attr.for]="fieldData.name" [ngClass]="{'uk-subLabel': fieldData.form.dependsOn !== null}"
       style="{{fieldData.label.style}}" [innerHTML]="fieldData.label.text">
    {{fieldData.form.mandatory ? ' (*)' : ''}}
  </div>
  <div *ngIf="fieldData.form.description.text" class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}} "
       [innerHTML]="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
  </div>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity" [formArrayName]="fieldData.name">
<!--    add multiplicity here-->
  </div>

  <ng-template #noMultiplicity>
    <input [formControlName]="fieldData.name" [id]="inputId" (input)="updateBitSet(fieldData); unsavedChangesPrompt()"
           (focus)="focus()" (focusout)="focusOut(false)" [step]='step' placeholder="{{fieldData.form.placeholder}}"
           class="uk-input uk-width-9-10 uk-animation-scale-up" [ngClass]="{'uk-form-danger': checkFormValidity()}">

    <p *ngIf="checkFormValidity()" class="uk-form-danger uk-animation-fade uk-text-italic">
      Value should be numeric{{getNumberOfDecimals() ? ' with up to ' + getNumberOfDecimals() + ' decimals seperated with \'.\'' : ''}}
    </p>

    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}"
           [innerHTML]="fieldData.form.suggestion.text">
      </div>
    </div>
  </ng-template>

</form>
