<form [formGroup]="form" class="uk-margin-small-bottom {{fieldData.form.display.cssClasses}}" [ngClass]="{'uk-hidden clear-style': hideField}">
  <div class="uk-margin-bottom">
    <span *ngIf="fieldData.label.text" class="uk-form-label uk-width-9-10 {{fieldData.label.cssClasses}}"
          [attr.for]="fieldData.name" style="{{fieldData.label.style}}" [innerHTML]="fieldData.label.text">
    </span>
    <span>{{fieldData.form.mandatory ? ' (*)' : ''}}</span>
  </div>
  <div *ngIf="fieldData.form.description.text" class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
       [innerHTML]="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
  </div>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity;" [formArrayName]="fieldData.name">
    <!--To add multiplicity if needed-->
  </div>
  <ng-template #noMultiplicity>
    <textarea [formControlName]="fieldData.name" [id]="inputId" class="uk-textarea uk-width-9-10 uk-animation-scale-up"
              [placeholder]="fieldData.form.placeholder ? fieldData.form.placeholder : ''"
              [maxlength]="fieldData.typeInfo.values ? fieldData.typeInfo.values[0] : null"
              (input)="updateBitSet(fieldData); unsavedChangesPrompt()" (focusin)="focus()" (focusout)="focusOut(false)"
              [ngClass]="{'uk-form-danger': checkFormValidity()}">
    </textarea>
    <div class="uk-width-9-10">
      <div class="uk-animation-scale-up" *ngIf="fieldData.typeInfo.values" style="float: right;">
        <span> {{form.get(fieldData.name).value ? form.get(fieldData.name).value.length : 0}}</span>
        <span>/ {{fieldData.typeInfo.values[0]}}</span>
      </div>
    </div>
    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}" [innerHTML]="fieldData.form.suggestion.text"></div>
    </div>
  </ng-template>
</form>
