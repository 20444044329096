export const EoscReadiness2022MapSubtitles: string[][] = [
  ['Has national policy', 'Does not have national policy'],
  ['Has financial strategy', 'Does not have financial strategy'],
  ['Has national monitoring', 'Does not have national monitoring'],
  ['Has use cases', 'Does not have use cases'],
  ['Has national monitoring', 'Does not have national monitoring'],
]

export const ColorPallet = ['#008792', '#E76F51', '#a9a9a9'];

export const countriesNumbers = [
  {id : 'AD', name : 'Andorra', gdp: 0, gerd: 0, income: 0},
  {id : 'AL', name : 'Albania', gdp: 15157.4, gerd: 0, income: 0},
  {id : 'AM', name : 'Armenia', gdp: 0, gerd: 0, income: 0},
  {id : 'AT', name : 'Austria', gdp: 405241.4, gerd: 13225.489, income: 408139},
  {id : 'BY', name : 'Belarus', gdp: 0, gerd: 0, income: 0},
  {id : 'BE', name : 'Belgium', gdp: 502521.1, gerd: 17235.425, income: 507170},
  {id : 'BA', name : 'Bosnia and Herzegovina', gdp: 19995.1, gerd: 38.099, income: 0},
  {id : 'BG', name : 'Bulgaria', gdp: 71077, gerd: 549.138, income: 67983},
  {id : 'HR', name : 'Croatia', gdp: 58290.9, gerd: 725.17, income: 58478},
  {id : 'CZ', name : 'Czech Republic', gdp: 238249.5, gerd: 4755.475, income: 228496},
  {id : 'CY', name : 'Cyprus', gdp: 24927.6, gerd: 199.497, income: 21979},
  {id : 'DK', name : 'Denmark', gdp: 342961.7, gerd: 9470.62, income: 356249},
  {id : 'EE', name : 'Estonia', gdp: 31169.0, gerd: 550.962, income: 30837},
  {id : 'FO', name : 'Faroe Islands', gdp: 0, gerd: 0, income: 0},
  {id : 'FI', name : 'Finland', gdp: 250923.0, gerd: 7491.1, income: 254839},
  {id : 'FR', name : 'France', gdp: 2502118.0, gerd: 55498.859, income: 2557213},
  {id : 'DE', name : 'Germany', gdp: 3617450.0, gerd: 113183.578, income: 3756796},
  // {id : 'GI', name : 'Gibraltar', gdp: 0, gerd: 0, income: 0},
  {id : 'GE', name : 'Georgia', gdp: 0, gerd: 0, income: 0},
  {id : 'GR', name : 'Greece', gdp: 181674.6, gerd: 2645.942, income: 0},
  // {id : 'GG', name : 'Guernsey', gdp: 0, gerd: 0, income: 0},
  // {id : 'VA', name : 'Holy See (Vatican City State)', gdp: 0, gerd: 0, income: 0},
  {id : 'HU', name : 'Hungary', gdp: 153963.3, gerd: 2531.109, income: 149418},
  {id : 'IS', name : 'Iceland', gdp: 21647.7, gerd: 606.28, income: 0},
  {id : 'IE', name : 'Ireland', gdp: 434069, gerd: 4820.975, income: 323101},
  {id : 'IL', name : 'Israel', gdp: 0, gerd: 0, income: 0},
  // {id : 'IM', name : 'Isle of Man', gdp: 0, gerd: 0, income: 0},
  {id : 'IT', name : 'Italy', gdp: 1822344.5, gerd: 25991.328, income: 1843641},
  // {id : 'JE', name : 'Jersey', gdp: 0, gerd: 0, income: 0},
  {id : 'LV', name : 'Latvia', gdp: 33348.9, gerd: 248.858, income: 32833},
  // {id : 'LI', name : 'Liechtenstein', gdp: 6075.3, gerd: 0, income: 0},
  {id : 'LT', name : 'Lithuania', gdp: 56478.1, gerd: 623.723, income: 53982},
  {id : 'LU', name : 'Luxembourg', gdp: 72360.9, gerd: 753.422, income: 50328},
  {id : 'MT', name : 'Malta', gdp: 15292.6, gerd: 99.917, income: 13900},
  {id : 'MD', name : 'Moldova, Republic of', gdp: 0, gerd: 0, income: 0},
  // {id : 'MC', name : 'Monaco', gdp: 0, gerd: 0, income: 0},
  {id : 'ME', name : 'Montenegro', gdp: 4955.1, gerd: 0, income: 0},
  {id : 'NL', name : 'Netherlands', gdp: 870587.0, gerd: 19751.581, income: 884666},
  {id : 'MK', name : 'North Macedonia', gdp: 11688.2, gerd: 0, income: 0},
  {id : 'NO', name : 'Norway', gdp: 414394.9, gerd: 8030.873, income: 425734},
  {id : 'PL', name : 'Poland', gdp: 576382.6, gerd: 8252.837, income: 548678},
  {id : 'PT', name : 'Portugal', gdp: 216053.2, gerd: 3609.191, income: 213893},
  {id : 'RO', name : 'Romania', gdp: 241268.4, gerd: 1141.236, income: 0},
  {id : 'RU', name : 'Russian Federation', gdp: 0, gerd: 0, income: 0},
  // {id : 'SM', name : 'San Marino', gdp: 0, gerd: 0, income: 0},
  {id : 'RS', name : 'Serbia', gdp: 53345.3, gerd: 530.141, income: 0},
  {id : 'SK', name : 'Slovakia', gdp: 100255.7, gerd: 918.346, income: 99132},
  {id : 'SI', name : 'Slovenia', gdp: 52278.8, gerd: 1112.49, income: 51215},
  {id : 'ES', name : 'Spain', gdp: 1222290.0, gerd: 17249.25, income: 1231787},
  // {id : 'SJ', name : 'Svalbard and Jan Mayen', gdp: 0, gerd: 0, income: 0},
  {id : 'SE', name : 'Sweden', gdp: 540734.0, gerd: 18396.62, income: 560898},
  {id : 'CH', name : 'Switzerland', gdp: 687568.4, gerd: 22734.11, income: 0},
  {id : 'TR', name : 'Turkey', gdp: 690246.0, gerd: 9677.894, income: 0},
  {id : 'UA', name : 'Ukraine', gdp: 0, gerd: 0, income: 0},
  {id : 'GB', name : 'United Kingdom', gdp: 0, gerd: 0, income: 0},
  // {id : 'AX', name : 'Åland Islands', gdp: 0, gerd: 0, income: 0}
];
