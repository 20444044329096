<form *ngIf="form" [formGroup]="form" class="uk-margin-small-bottom {{fieldData.form.display.cssClasses}}"
      [ngClass]="{'uk-hidden clear-style': (hideField || !fieldData.form.display.visible)}">
  <div class="uk-margin-bottom">
    <span *ngIf="fieldData.label.text" class="uk-form-label {{fieldData.label.cssClasses}}" [attr.for]="fieldData.name"
          [ngClass]="{'uk-subLabel': fieldData.form.dependsOn !== null}" style="{{fieldData.label.style}}"
          [innerHTML]="fieldData.label.text">
    </span>
    <span>{{fieldData.form.mandatory ? ' (*)' : ''}}</span>
  </div>
  <div *ngIf="fieldData.form.description.text" class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
       [innerHTML]="fieldData.form.description.text | safeUrl: 'html'" style="{{fieldData.form.description.style}}">
  </div>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity" [formArrayName]="fieldData.name"></div>

  <ng-template #noMultiplicity>

    <div class="radio-container uk-padding-small uk-width-1-1 uk-child-width-expand">
      <div class="uk-form-label uk-flex uk-flex-center">{{fieldData.typeInfo.values[1]}}</div>
      <div *ngFor="let radio of iterationArr; let i = index" class="radio-button">
        <label for="radio{{i}}" class="radio-label uk-form-label uk-margin-small-bottom">{{i+1}}</label>
        <input type="radio" id="radio{{i}}" class="uk-radio"
               [formControlName]="fieldData.name" [name]="fieldData.name" [value]="i+1"
               [ngClass]="{'uk-form-danger': checkFormValidity()}">
      </div>
      <div class="uk-form-label uk-flex uk-flex-center">{{fieldData.typeInfo.values[2]}}</div>
    </div>


    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}"
           [innerHTML]="fieldData.form.suggestion.text">
      </div>
    </div>
  </ng-template>

</form>
