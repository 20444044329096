<form *ngIf="form" [formGroup]="form" class="{{fieldData.form.display?.cssClasses}}" [ngClass]="{'uk-hidden clear-style': hideField}">
<!--  {{fieldData.name | json}}-->
  <div *ngIf="fieldData.label?.text" class="uk-form-label uk-margin-bottom {{fieldData.label?.cssClasses}}"
       [attr.for]="fieldData.name" style="{{fieldData.label.style}}">
    {{fieldData.label?.text + (fieldData.form.mandatory ? ' (*)' : '')}}
  </div>

  <ng-container *ngIf="fieldData.form.description?.showLess; else noReadMore">
    <div *ngIf="fieldData.form.description.text" class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
         style="{{fieldData.form.description.style}}">
    <read-more-text [maxHeight]="46" [text]="fieldData.form.description.text"></read-more-text>
    </div>
  </ng-container>
  <ng-template #noReadMore>
    <div *ngIf="fieldData.form.description?.text"
         class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
         [innerHTML]="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
      <!--  [innerHTML]="'<read-more-text [maxHeight]=&quot260&quot [text]=\'asdsad\'></read-more-text>'">-->
    </div>
  </ng-template>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity">
    <div *ngFor="let arrayControl of fieldAsFormArray().controls; let i=index">
      <!-- Show radio so user can choose one-->
      <div class="uk-margin-small-bottom">
        <div *ngFor="let field of fieldData.subFields; let j=index">
          <label class="uk-form-label">
            <input class="uk-radio uk-margin-small-right" type="radio" [name]="i+'-'+fieldData.name" [checked]="j===0"
                   (click)="chooseOne(field.name, i)">
            {{field.label.text}}
          </label>
        </div>
      </div>
      <div [formGroupName]="i" class="uk-width-9-10 uk-display-inline-block" [ngClass]="{'uk-margin-bottom formGroupElement': fieldData.form.display?.hasBorder}">
        <div *ngFor="let control of fieldData?.subFields; let j=index">
          <div class="">
            <div *ngIf="getGroupOfArray(i).contains(control.name)">
              <ng-container *ngIf="control.typeInfo.type === 'composite'">
                <app-composite-field [fieldData]="control" [subVocabularies]="subVocabularies"
                                     [vocabularies]="vocabularies" (hasChanges)="unsavedChangesPrompt()"
                                     [position]="i" (handleBitSets)="handleBitsetOfChildren($event)"
                                     (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-composite-field>
              </ng-container>
              <ng-container *ngIf="control.typeInfo.type === 'string' || control.typeInfo.type === 'double' ||
                    control.typeInfo.type === 'email' || control.typeInfo.type === 'phone' ||
                    control.typeInfo.type === 'url'">
                <app-string-url-email-field [fieldData]="control" [position]="i" (hasChanges)="unsavedChangesPrompt()"
                                            (handleBitSets)="handleBitsetOfChildren($event)"
                                            (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
                </app-string-url-email-field>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <a *ngIf="fieldAsFormArray().length > 1" class="remove-element uk-padding-small uk-display-inline-block"
         (click)="remove(i); updateBitSetOfComposite(fieldData, i); unsavedChangesPrompt();">
        <span class="material-icons">clear</span>
      </a>
    </div>
    <div *ngIf="form.enabled" class="uk-width-9-10 uk-text-center uk-margin-top">
      <a class="add-new-element uk-flex uk-flex-middle uk-flex-center" (click)="pushComposite(fieldData)">
        <span class="material-icons">add</span>Add &nbsp;<span [innerHTML]="fieldData.label.text"></span>
      </a>
    </div>
  </div>

  <ng-template #noMultiplicity>
    <!-- Show radio so user can choose one-->
    <div class="uk-margin-small-bottom">
      <div *ngFor="let field of fieldData.subFields; let i=index">
        <label class="uk-form-label">
          <input class="uk-radio uk-margin-small-right" type="radio" [name]="fieldData.name" [checked]="i===0"
                 (click)="chooseOne(field.name)">
          {{field.label.text}}
        </label>
      </div>
    </div>

    <div class="uk-width-1-1 uk-display-inline-block" [ngClass]="{'uk-margin-bottom formGroupElement': fieldData.form.display?.hasBorder}">
      <div *ngFor="let control of fieldData?.subFields; let j=index">
        <div class="">
          <div *ngIf="this.form.contains(control.name) && control.typeInfo.type === 'composite'">
            <app-composite-field [fieldData]="control" [subVocabularies]="subVocabularies" [vocabularies]="vocabularies"
                                 (handleBitSets)="handleBitsetOfChildren($event)" (hasChanges)="unsavedChangesPrompt()"
                                 (handleBitSetsOfComposite)="handleCompositeBitsetOfChildren($event)">
            </app-composite-field>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="fieldData.form.suggestion?.text" class="uk-margin-top">
    <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}" [innerHTML]="fieldData.form.suggestion.text"></div>
  </div>

</form>
<!--<pre>{{fieldData | json}}</pre>-->
<!--<pre>{{form.getRawValue() | json}}</pre>-->
