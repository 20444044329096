<div class="uk-card uk-card-default uk-card-body">
  <h5 class="uk-flex uk-flex-middle">
    <span class="uk-margin-right"><img src="{{cardIcon}}" width="30" height="35"></span>
    <span>{{cardTitle}}</span>
  </h5>
  <div class="uk-text-small">
    <div [ngClass]="(hasSubcategories)?'uk-grid uk-grid-divider uk-child-width-1-3':''">
      <ng-content></ng-content>
    </div>
  </div>
</div>
