import {Component} from "@angular/core";

@Component({
  selector: 'app-contributions-home',
  templateUrl: './contributions-home.component.html'
})

export class ContributionsHomeComponent {

  title: string = 'Title';
  text: string = 'Text';

}
