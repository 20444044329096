<div *ngIf="surveyAnswer" class="uk-grid uk-grid-divider">
  <div class="uk-width-2-3">
    <div class="uk-card uk-card-default uk-card-body">
      <!--  <h5 class="uk-flex uk-flex-middle">-->
      <!--    <span class="uk-margin-right"><img src="{{cardIcon}}" width="30" height="35"></span>-->
      <!--    <span>{{cardTitle}}</span>-->
      <!--  </h5>-->
      <div class="uk-text-small">
        <div class="uk-grid uk-child-width-1-2">
          <div class="">
            <h5 *ngIf="!surveyAnswer['General']?.['Question1']?.['Question1-0'] &&
                       !surveyAnswer['General']?.['Question1']?.['Question1-1-1'] else elseOA"> N/A</h5>

            <ng-template #elseOA>
              <h5>{{surveyAnswer['General']?.['Question1']?.['Question1-1'] === 'Yes' ?
                surveyAnswer['General']?.['Question1']?.['Question1-0'] : surveyAnswer['General']?.['Question1']?.['Question1-1-1']}}
              </h5>
            </ng-template>
            <div class="text">Researchers employed in 2021 in full-time equivalents</div>
          </div>
          <div class="">
            <h5 *ngIf="surveyAnswer['General']?.['Question4']?.['Question4-0']" class="uk-margin-remove">{{surveyAnswer['General']?.['Question4']?.['Question4-0']}}</h5>
            <h5 *ngIf="!surveyAnswer['General'] || !(surveyAnswer['General']?.['Question4']) || !(surveyAnswer['General']?.['Question4']?.['Question4-0'])" class="uk-margin-remove">N/A</h5>
            <div class="text">Repositories</div>
          </div>
          <div class="uk-grid-margin">
            <h5 *ngIf="surveyAnswer['General']?.['Question2']?.['Question2-0']" class="uk-margin-remove">{{surveyAnswer['General']?.['Question2']?.['Question2-0']}}</h5>
            <h5 *ngIf="!surveyAnswer['General'] || !(surveyAnswer['General']?.['Question2']) || !(surveyAnswer['General']?.['Question2']?.['Question2-0'])" class="uk-margin-remove">N/A</h5>
            <div class="text">RPOs</div>
          </div>
          <div class="uk-grid-margin">
            <h5 *ngIf="surveyAnswer['General']?.['Question3']?.['Question3-0']" class="uk-margin-remove">{{surveyAnswer['General']?.['Question3']?.['Question3-0']}}</h5>
            <h5 *ngIf="!surveyAnswer['General'] || !(surveyAnswer['General']?.['Question3']) || !(surveyAnswer['General']?.['Question3']?.['Question3-0'])" class="uk-margin-remove">N/A</h5>
            <div class="text">RFOs</div>
          </div>
          <div class="uk-grid-margin">
            <h5 *ngIf="surveyAnswer['General']?.['Question5']?.['Question5-0']" class="uk-margin-remove">{{surveyAnswer['General']?.['Question5']?.['Question5-0']}} Μ</h5>
            <h5 *ngIf="!surveyAnswer['General'] || !(surveyAnswer['General']?.['Question5']) || !(surveyAnswer['General']?.['Question5']?.['Question5-0'])" class="uk-margin-remove">N/A</h5>
            <span>financial investments in total in EOSC and Open Science in 2021</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--          Comments....-->
  <div class="uk-width-1-3">
    <ul *ngIf="surveyAnswer['General']" uk-accordion="collapsible: false" class="uk-text-small">
      <li *ngIf="surveyAnswer['General']?.['Question1']?.['Question1-2']" class="uk-margin-medium-bottom">
        <a class="uk-accordion-title" href>
          <span class="comment-number uk-margin-small-right">-</span>
          <span class="comment-title">Number of researchers employed in 2021</span>
        </a>
        <div class="uk-accordion-content">
          <p>{{surveyAnswer['General']?.['Question1']?.['Question1-2']}}</p>
        </div>
      </li>
      <li *ngIf="surveyAnswer['General']?.['Question2']?.['Question2-1']" class="uk-margin-medium-bottom">
        <a class="uk-accordion-title" href>
          <span class="comment-number uk-margin-small-right">-</span>
          <span class="comment-title">Number of RPOs</span>
        </a>
        <div class="uk-accordion-content">
          <div [innerHTML]="surveyAnswer['General']?.['Question2']?.['Question2-1']"></div>
        </div>
      </li>
      <li *ngIf="surveyAnswer['General']?.['Question3']?.['Question3-1']" class="uk-margin-medium-bottom">
        <a class="uk-accordion-title" href>
          <span class="comment-number uk-margin-small-right">-</span>
          <span class="comment-title">Number of RFOs</span>
        </a>
        <div class="uk-accordion-content">
          <p>{{surveyAnswer['General']?.['Question3']?.['Question3-1']}}</p>
        </div>
      </li>
      <li *ngIf="surveyAnswer['General']?.['Question4']?.['Question4-1']" class="uk-margin-medium-bottom">
        <a class="uk-accordion-title" href>
          <span class="comment-number uk-margin-small-right">-</span>
          <span class="comment-title">Number of repositories</span>
        </a>
        <div class="uk-accordion-content">
          <p>{{surveyAnswer['General']?.['Question4']?.['Question4-1']}}</p>
        </div>
      </li>
      <li *ngIf="surveyAnswer['General']?.['Question5']?.['Question5-1']" class="uk-margin-medium-bottom">
        <a class="uk-accordion-title" href>
          <span class="comment-number uk-margin-small-right">-</span>
          <span class="comment-title">Total financial investments in EOSC and Open Science</span>
        </a>
        <div class="uk-accordion-content">
          <p>{{surveyAnswer['General']?.['Question5']?.['Question5-1']}}</p>
        </div>
      </li>
    </ul>
  </div>
</div>

