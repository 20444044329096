<div class="uk-section-default uk-container uk-container-large uk-padding"  style="min-height: calc(100svh - var(--header-height))">
  <h3>Messages</h3>

  <div class="uk-flex uk-flex-right">
    <button class="uk-button uk-button-secondary uk-flex uk-flex-middle" uk-toggle="target: #emailCompose">
      <span class="material-icons md-18 uk-margin-small-right">edit</span>New Message
    </button>
  </div>

  <ul id="tab" uk-tab>
    <li class="uk-active" (click)="selectedTopics = []; clearFilters()">
      <a href="#" [routerLink]='"."' [fragment]="'inbox'"><span class="material-icons md-18">inbox</span>Inbox</a>
    </li>
    <li (click)="selectedTopics = []; clearFilters()">
      <a href="#" [routerLink]='"."' [fragment]="'sent'"><span class="material-icons md-18">send</span>Sent</a>
    </li>
  </ul>

  <div class="uk-margin-remove" uk-grid>
    <div class="uk-width-1-6 uk-padding-small uk-padding-remove-horizontal">
      <ng-select id="order" clearAllText="Clear" placeholder="Order by date" [(ngModel)]="order"
                 (change)="changeOrder(order)">
        <ng-option [value]="'DESC'">Descending</ng-option>
        <ng-option [value]="'ASC'">Ascending</ng-option>
      </ng-select>
    </div>

    <div class="uk-width-expand uk-flex uk-flex-right">
    <div class="uk-padding-small uk-padding-remove-horizontal">
      <div class="uk-inline">
        <span class="uk-form-icon" uk-icon="icon: search"></span>
<!--        <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: close; ratio: 0.6"-->
<!--           (click)="updateUrlParams('regex', null); searchTerm = null"></a>-->
        <input type="text" #searchInput class="uk-input uk-form-width-medium" placeholder="Search..."
               [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}">
      </div>
    </div>
    </div>
  </div>

  <ul id="messages" class="uk-switcher">
    <li class="uk-animation-fade">
      <table id="inboxTable" class="uk-table uk-table-divider uk-table-middle uk-background-default uk-border-rounded">
        <thead>
        <tr>
          <th class="" >
            <input id="selectAllInbox" name="inbox" class="uk-checkbox" type="checkbox" (click)="toggle($event)"
                   [checked]="selectedTopics.length === inbox?.length && inbox?.length > 0">
          </th>
          <th class="uk-width-auto">
            <div class="uk-flex uk-flex-middle">
              <a class="material-icons" title="Refresh" (click)="refreshInbox(); clearFilters()">refresh</a>
              <a *ngIf="selectedTopics.length > 0" class="material-icons uk-margin-left" title="Mark as unread"
                 (click)="batchAction(false)">mark_as_unread</a>
              <a *ngIf="selectedTopics.length > 0" class="material-icons uk-margin-left" title="Mark as read"
                 (click)="batchAction(true)">mark_email_read
              </a>
  <!--            <a class="material-icons uk-margin-left" [ngClass]="{'uk-disabled': !(selectedTopics.length > 0)}"-->
  <!--               title="Move to archive">archive-->
  <!--            </a>-->

            </div>
          </th>
          <th class="uk-width-auto"></th>
          <th  class="">
            <div *ngIf="page?.totalElements" class="uk-flex uk-flex-middle">
              <span> {{page?.pageable.offset + 1}} - {{page?.pageable.offset + page?.numberOfElements}} of {{page?.totalElements}}</span>
              <a class="material-icons" (click)="previous()">keyboard_arrow_left</a>
              <a class="material-icons" (click)="next()">keyboard_arrow_right</a>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="!inbox || inbox?.length === 0; else showInbox">
          <td></td>
          <td class="uk-width-expand">No messages</td>
        </tr>
        <ng-template #showInbox>
          <tr *ngFor="let topic of inbox" [ngClass]="{'message-unread-background': !topic.read}"
              class="uk-visible-toggle">
            <td class="uk-width-auto">
              <input class="uk-checkbox" type="checkbox" aria-label="Checkbox" (change)="toggleCheck($event, topic)">
            </td>
            <td class="uk-width-1-6" [routerLink]="[topic.id]" [fragment]="'message' + (topic.messages?.length-1)"
                [state]="{returnTo: fragment}" style="cursor: pointer">
              <div [ngClass]="{'uk-text-bolder': !topic.read}">{{topic.from?.name}}</div>
              <div class="uk-text-muted">{{topic.updated | date}}</div>
            </td>
            <td class="uk-text-truncate uk-width-2-3" [routerLink]="[topic.id]" [state]="{returnTo: fragment}"
                [fragment]="'message' + (topic.messages.length-1)" style="cursor: pointer">
              <div [ngClass]="{'uk-text-bolder': !topic.read}">{{topic.subject}}</div>
              <div>
                <div class="uk-text-muted line-clamp" [innerHtml]="topic.messages[topic.messages?.length-1].body"></div>
              </div>
              <div class="uk-text-right">
              <span *ngFor="let tag of topic.tags" class="uk-margin-small-left">
                <span class="uk-badge badge-default">{{tag}}</span>
              </span>
              </div>
            </td>
            <td class="uk-width-auto uk-text-right uk-invisible-hover">
              <!--            <span>-->
              <!--              <a class="material-icons uk-margin-left" title="Move to archive">archive</a>-->
              <!--            </span>-->
              <span *ngIf="!topic.read">
              <a class="material-icons uk-margin-left" title="Mark as read" (click)="markAsReadUnread(topic, true)">mark_email_read</a>
            </span>
              <span *ngIf="topic.read" (click)="markAsReadUnread(topic, false)">
              <a class="material-icons uk-margin-left" title="Mark as unread">mark_as_unread</a>
            </span>
            </td>
          </tr>
        </ng-template>
        </tbody>
      </table>
    </li>

    <li class="uk-animation-fade">
      <table id="outboxTable" class="uk-table uk-table-divider uk-table-middle uk-background-default uk-border-rounded">
        <thead>
        <tr>
          <th>
            <input id="selectAllOutbox" name="sent" class="uk-checkbox" type="checkbox" (click)="toggle($event)"
                   [checked]="selectedTopics.length === sent?.length && sent?.length > 0">
          </th>
          <th class="uk-width-auto">
            <div class="uk-flex uk-flex-middle">
              <a class="material-icons" title="Refresh" (click)="refreshOutbox(); clearFilters()">refresh</a>
              <a *ngIf="selectedTopics.length > 0" class="material-icons uk-margin-left" title="Mark as unread"
                 (click)="batchAction(false)">mark_as_unread</a>
              <a *ngIf="selectedTopics.length > 0" class="material-icons uk-margin-left" title="Mark as read"
                 (click)="batchAction(true)">mark_email_read
              </a>
              <!--            <a class="material-icons uk-margin-left" [ngClass]="{'uk-disabled': !(selectedTopics.length > 0)}"-->
              <!--               title="Move to archive">archive-->
              <!--            </a>-->
            </div>
          </th>
          <th class="uk-width-auto"></th>
          <th class="uk-width-auto">
            <div *ngIf="page?.totalElements" class="uk-flex uk-flex-middle">
              <span> {{page?.pageable.offset + 1}} - {{page?.pageable.offset + page?.numberOfElements}} of {{page?.totalElements}}</span>
              <a class="material-icons" (click)="previous()">keyboard_arrow_left</a>
              <a class="material-icons" (click)="next()">keyboard_arrow_right</a>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="!sent || sent?.length === 0; else showSent">
          <td></td>
          <td class="uk-width-expand">No messages</td>
        </tr>
        <ng-template #showSent>
          <tr *ngFor="let topic of sent" [ngClass]="{'message-unread-background': !topic.read}"
              class="uk-visible-toggle">
            <td class="uk-width-auto">
              <input class="uk-checkbox" type="checkbox" aria-label="Checkbox" (change)="toggleCheck($event, topic)">
            </td>
            <td class="uk-width-1-6" [routerLink]="[topic.id]" [fragment]="'message' + (topic.messages.length-1)"
                [state]="{returnTo: fragment}" style="cursor: pointer">
              <div [ngClass]="{'uk-text-bolder': !topic.read}">
                {{topic.from.name}}
              </div>
              <div class="uk-text-muted">{{topic.updated | date}}</div>
            </td>
            <td class="uk-text-truncate uk-width-2-3" [routerLink]="[topic.id]" [state]="{returnTo: fragment}"
                [fragment]="'message' + (topic.messages.length-1)" style="cursor: pointer">
              <div [ngClass]="{'uk-text-bolder': !topic.read}">{{topic.subject}}</div>
              <div>
                <div class="uk-text-muted line-clamp" [innerHtml]="topic.messages[topic.messages.length-1].body"></div>
              </div>
              <div class="uk-text-right">
              <span *ngFor="let tag of topic.tags" class="uk-margin-small-left">
                <span class="uk-badge badge-default">{{tag}}</span>
              </span>
              </div>
            </td>
            <td class="uk-width-auto uk-text-right uk-invisible-hover">
              <!--            <span>-->
              <!--              <a class="material-icons uk-margin-left" title="Move to archive">archive</a>-->
              <!--            </span>-->
              <span *ngIf="!topic.read">
              <a class="material-icons uk-margin-left" title="Mark as read" (click)="markAsReadUnread(topic, true)">mark_email_read</a>
            </span>
              <span *ngIf="topic.read" (click)="markAsReadUnread(topic, false)">
              <a class="material-icons uk-margin-left" title="Mark as unread">mark_as_unread</a>
            </span>
            </td>
          </tr>
        </ng-template>
        </tbody>
      </table>
    </li>

  </ul>

</div>

<app-email-compose id="emailCompose" class="uk-modal-container" [groupId]="groupId" uk-modal></app-email-compose>
