<div id="header_main">
  <div class="main-menu tm-header uk-visible@m tm-header-transparent">

    <div class="uk-navbar-container">
      <div class="uk-container uk-container-expand">

        <nav class="uk-navbar fixed-navbar">

          <div class="uk-navbar-left">
            <a class="logo" [routerLink]="['home']">
              <img src="../../../../assets/images/eosc-logo-bw.svg" width="220px">
            </a>
          </div>

          <div class="uk-navbar-center">
            <ul class="uk-navbar-nav user_actions">
              <li>
                <a class="uk-icon uk-animation-fade" [routerLink]="['/eoscreadiness']" aria-expanded="false">
                  <span class="uk-text-small uk-margin-small-right">EOSC Readiness</span>
                </a>
              </li>
<!--              Show contact -->
              <li>
                <a class="uk-icon uk-animation-fade" [routerLink]="['/contact']" aria-expanded="false">
                  <span class="uk-text-small uk-margin-small-right">Contact</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav user_actions">
              <li>
                <a *ngIf="userInfo" class="uk-icon" aria-expanded="false">
                  <div class="uk-flex">
                    <div class="uk-padding-remove uk-flex uk-flex-middle">
                      <!--                      <span class="uk-text-small uk-text-bold uk-width-1-1">{{userInfo.user.fullname}}</span>-->
                      <span *ngIf="currentCoordinator || currentStakeholder || currentAdministrator" class="">
                        {{(currentStakeholder?.name ? currentStakeholder.name : (currentCoordinator?.name ? currentCoordinator.name : currentAdministrator.name))}}
                      </span>
                    </div>
                    <div *ngIf="!userInfo.user.profile?.picture; else elseBlock">
                      <svg height="60" width="60">
                        <circle cx="30" cy="30" r="20" stroke-width="1"></circle>
                        <text dy=".4em" font-size="16" text-anchor="middle" x="50%" y="50%">{{parseUsername()}}</text>
                        <circle class="notification" *ngIf="unreadMessages.totalUnread > 0" cx="45" cy="15" r="7" stroke-width="1"></circle>
                        <text class="notification"  *ngIf="unreadMessages.totalUnread > 0" x="45" y="19" text-anchor="middle">
                          {{unreadMessages?.totalUnread.toString()}}
                        </text>
                      </svg>
                    </div>
                    <ng-template #elseBlock >
                      <svg height="60" width="60">
                        <image x="10" y="10" [attr.href]="'data:image;base64,' + userInfo.user.profile.picture"
                               height="40" width="40" clip-path="inset(0% round 20px)"/>
<!--                        For messages-->
                        <circle class="notification" *ngIf="unreadMessages?.totalUnread > 0" cx="45" cy="15" r="7" stroke-width="1"></circle>
                        <text class="notification"  *ngIf="unreadMessages?.totalUnread > 0" x="45" y="19" text-anchor="middle">
                          {{unreadMessages?.totalUnread.toString()}}
                        </text>
                      </svg>
                    </ng-template>
                  </div>
                </a>
                <div *ngIf="userInfo" class="uk-padding-remove-horizontal uk-dropdown"
                     uk-dropdown="mode: click; offset: -2; delay-hide: 0; flip: false; pos: bottom-right">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li class="user-info">
                      <div>
                        <div class="uk-text-bold">{{userInfo.user.fullname}}</div>
                        <div class="uk-text-meta">{{userInfo.user.email}}</div>
                      </div>
                    </li>
                    <hr *ngIf="userInfo.stakeholders?.length > 0">
                    <li class="groups" *ngFor="let stakeholder of userInfo.stakeholders">
                      <a (click)="setGroup(stakeholder)"><span class="material-icons uk-margin-right">group</span>
                        {{stakeholder.name + (stakeholder.type === 'association' ? (', EOSC Association '+ (stakeholder.country ? '(' + stakeholder.country + ')': '')) : '') }}
<!--                        for messages-->
                        <svg *ngIf="showUnread(stakeholder.id)" height="20" width="20">
                          <circle class="notification" cx="7.5" cy="10" r="7" stroke-width="1"></circle>
                          <text class="notification"  x="7.2" y="13" text-anchor="middle">
                            {{showUnread(stakeholder.id)}}
                          </text>
                        </svg>
                      </a>
                    </li>
                    <hr *ngIf="userInfo.coordinators?.length > 0">
                    <li class="groups" *ngFor="let coordinator of userInfo.coordinators">
                      <a (click)="setCoordinator(coordinator)">
                        <span class="material-icons uk-margin-right">group</span>{{coordinator.name}}
<!--                        For messages-->
                        <svg *ngIf="showUnread(coordinator.id)" height="20" width="20">
                          <circle class="notification" cx="7.5" cy="10" r="7" stroke-width="1"></circle>
                          <text class="notification"  x="7.2" y="13" text-anchor="middle">
                            {{showUnread(coordinator.id)}}
                          </text>
                        </svg>
                      </a>
                    </li>
                    <ng-container *ngIf="userInfo.administrators?.length > 0">
                      <hr>
                      <li *ngFor="let admin of userInfo.administrators">
                        <a (click)="setAdministrator(admin)">
                          <span class="material-icons uk-margin-right">shield</span>{{admin.name}}
                          <!--                        For messages-->
                          <svg *ngIf="showUnread(admin.id)" height="20" width="20">
                            <circle class="notification" cx="7.5" cy="10" r="7" stroke-width="1"></circle>
                            <text class="notification"  x="7.2" y="13" text-anchor="middle">
                              {{showUnread(admin.id)}}
                            </text>
                          </svg>
                        </a>
                      </li>
                    </ng-container>
                    <hr *ngIf="showArchive">
                    <li *ngIf="showArchive" class="groups">
                      <a class="" style="cursor: default; pointer-events: none">
                        <span class="material-icons uk-margin-right">space_dashboard</span>
                        <span class="uk-text-uppercase uk-text-bold">Other dashboards</span>
                      </a>
                    </li>
                    <li *ngIf="showArchive" class="uk-margin-small-left">
                      <a class="" [routerLink]="['/country_selector']" aria-expanded="false">
                        <span class="uk-text-small uk-margin-medium-left">National Contributions by country</span>
                      </a>
                    </li>
                    <li *ngIf="showArchive" class="uk-margin-small-left">
                      <a class="" [routerLink]="['/eoscreadiness/2023']" aria-expanded="false">
                        <span class="uk-text-small uk-margin-medium-left">EOSC Readiness 2023</span>
                      </a>
                    </li>
                    <li *ngIf="showArchive" class="uk-margin-small-left">
                      <a class="" [routerLink]="['/archive']" aria-expanded="false">
                        <span class="uk-text-small uk-margin-medium-left">Archive 2021</span>
                      </a>
                    </li>
                    <hr>
                    <li>
                      <a class="" [routerLink]="['/profile']">
                        <span class="material-icons uk-margin-right">account_circle</span>My Profile
                      </a>
                    </li>
                    <hr>
                    <li>
                      <a id="logout" (click)="logout()"><span class="material-icons uk-margin-right">logout</span>Log out</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li *ngIf="!userInfo" class="uk-animation-fade">
                <a class="uk-button uk-button-link" (click)="logInButton()">Login</a>
              </li>
            </ul>
          </div>

        </nav>

      </div>

    </div>

  </div>
</div>

<!-- Consent modal -->
<div id="consent-modal" uk-modal="esc-close: false; bg-close: false ">
  <div class="uk-modal-dialog uk-modal-body">
    <!--    <h2 class="uk-modal-title">Headline</h2>-->
    <p>Click the link below and read it carefully. By pressing I agree you acknowledge that you have read and agree to
      the following terms.</p>
    <hr>
    <a *ngIf="acceptedPrivacyPolicy" target="_blank" href="../../../../assets/pdf/{{acceptedPrivacyPolicy.privacyPolicy.filename}}">EOSC Observatory Privacy Policy</a>
    <p class="uk-text-right">
      <button class="uk-button uk-button-secondary" type="button" (click)="closeModal()">Close</button>
      <button class="uk-button uk-button-primary" type="button" (click)="updateConsent()">I agree</button>
    </p>
  </div>
</div>
