<!--<router-outlet></router-outlet>-->

<ng-container *ngIf="isContributionsDashboardRoute() ; else public">
  <app-contributions-dashboard></app-contributions-dashboard>
</ng-container>

<ng-template #public>

  <ng-container *ngIf="isEOSCReadinessDashboardRoute() ; else home">
    <app-top-menu-dashboard></app-top-menu-dashboard>
    <div style="margin-top: 80px"></div>
    <router-outlet></router-outlet>
    <div style="margin-left: 320px">
      <app-footer></app-footer>
    </div>
  </ng-container>

  <ng-template #home>
    <app-top-menu-dashboard></app-top-menu-dashboard>
    <!--  <div style="margin-top: 90px"></div>-->
    <div style="margin-top: 80px"></div>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </ng-template>

</ng-template>
