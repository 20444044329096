<aside *ngIf="ready" id="sidebar_main">

  <div id="sidebar_toggle" class="" (click)="toggleSidebar()"></div>

  <div>
    <div class="menu_section">
      <ul>

        <li [routerLinkActive]="['current_section']" class="">
          <a [routerLink]="['contributions/'+(currentStakeholder ? currentStakeholder.id : (currentCoordinator ? currentCoordinator.id : currentAdministrator.id))+'/home']" class="uk-flex uk-flex-middle">
            <span class="material-icons uk-margin-right">home</span>
            <span class="menu_title">Home</span>
          </a>
        </li>

      </ul>
    </div>

    <div class="menu_section border_top">
      <ul *ngIf="currentStakeholder">

        <li [routerLinkActive]="['current_section']" class="">
          <a [routerLink]="['contributions/'+currentStakeholder.id+'/mySurveys']" class="uk-flex uk-flex-middle">
            <span class="material-icons uk-margin-right">assignment</span>
            <span class="menu_title">My Surveys</span>
          </a>
        </li>

        <li [routerLinkActive]="['current_section']" class="">
          <a [routerLink]="['contributions/'+currentStakeholder.id+'/group']" class="uk-flex uk-flex-middle">
            <span class="material-icons uk-margin-right">group</span>
            <span class="menu_title">My Group</span>
          </a>
        </li>

        <li *ngIf="isManager" [routerLinkActive]="['current_section']">
          <a [routerLink]="['contributions/'+currentStakeholder.id+'/surveys']" class="uk-flex uk-flex-middle">
            <span class="material-icons uk-margin-right">assignment</span>
            <span class="menu_title">{{currentStakeholder.type | uppercase}} Surveys</span>
          </a>
        </li>

      </ul>
      <ul *ngIf="currentCoordinator">

        <li [routerLinkActive]="['current_section']" class="">
          <a [routerLink]="['contributions/'+currentCoordinator.id+'/surveys']" class="uk-flex uk-flex-middle">
            <span class="material-icons uk-margin-right">assignment</span>
            <span class="menu_title">Surveys</span>
          </a>
        </li>

        <li [routerLinkActive]="['current_section']" class="">
          <a [routerLink]="['contributions/'+currentCoordinator.id+'/surveyTemplates']" class="uk-flex uk-flex-middle">
            <span class="material-icons uk-margin-right">assignment</span>
            <span class="menu_title">Survey Templates</span>
          </a>
        </li>

      </ul>
<!--      For messages-->
      <ul *ngIf="projectName === 'Observatory'">
        <li [routerLinkActive]="['current_section']" class="">
          <a [routerLink]="['contributions/'+(currentStakeholder ? currentStakeholder.id : (currentCoordinator ? currentCoordinator.id : currentAdministrator.id))+'/messages']"
             class="uk-flex uk-flex-middle">
            <span class="material-icons uk-margin-right">mail</span>
            <span class="menu_title">Messages</span>
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="currentCoordinator || currentAdministrator" class="menu_section border_top">
      <ul>
        <li [routerLinkActive]="['current_section']">
          <a [routerLink]="['contributions/'+ (currentCoordinator ? currentCoordinator.id : currentAdministrator.id) +'/stakeholders']" class="uk-flex uk-flex-middle">
            <span class="material-icons uk-margin-right">manage_accounts</span>
            <span class="menu_title">Stakeholders</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="menu_section border_top sidebar-footer">
      <ul>

        <li>
          <a class="uk-flex uk-flex-middle" href="mailto:stefania.martziou@athenarc.gr">
            <span class="material-icons uk-margin-right">help</span>
            <span class="menu_title uk-flex uk-flex-middle">
            <span>Support</span>
            <span class="material-icons md-18 uk-margin-left">forward_to_inbox</span>
          </span>
          </a>
        </li>

        <li>
          <a class="uk-flex uk-flex-middle" href="../assets/pdf/EOSC-SB%20Privacy%20Policy%20V2.0.pdf">
            <span class="material-icons uk-margin-right">policy</span>
            <span class="menu_title uk-flex uk-flex-middle">
            <span>Privacy policy</span>
            <span class="material-icons md-18 uk-margin-left">open_in_new</span>
          </span>
          </a>
        </li>

        <li>
          <a class="uk-flex uk-flex-middle" href="../assets/pdf/EOSC%20Observatory%20Acceptable%20Use%20Policy%20V1.0.pdf">
            <span class="material-icons uk-margin-right">policy</span>
            <span class="menu_title uk-flex uk-flex-middle">
            <span>Use Policy</span>
            <span class="material-icons md-18 uk-margin-left">open_in_new</span>
          </span>
          </a>
        </li>

      </ul>
    </div>
  </div>

</aside><!-- main sidebar end -->
