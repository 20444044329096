<form *ngIf="formControl" [formGroup]="form" class="uk-margin-small-bottom {{fieldData.display.cssClasses}}"
      [ngClass]="{'uk-hidden clear-style': hideField || !fieldData.form.display.visible}">
  <div *ngIf="fieldData.label.text" class="uk-form-label uk-margin-bottom {{fieldData.label.cssClasses}}"
       [attr.for]="fieldData.name" style="{{fieldData.label.style}}" [innerHTML]="fieldData.label.text">
    {{fieldData.form.mandatory ? ' (*)' : ''}}
  </div>
  <div *ngIf="fieldData.form.description.text" class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
       [innerHTML]="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
  </div>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity" [formArrayName]="fieldData.name">
  <!--Add Multiplicity here if needed-->
  </div>

  <ng-template #noMultiplicity >
    <div *ngFor="let value of fieldData.typeInfo.values; let i=index" class="uk-margin-small-top">
      <label class="uk-form-label">
        <input class="uk-radio uk-margin-small-right " type="radio" onchange="this.setAttribute('value', this.value)"
               [formControlName]="fieldData.name" [name]="fieldData.name" [value]="value" [id]="inputId+'['+i+']'"
               (input)="updateBitSet(fieldData); unsavedChangesPrompt()" (focusin)="focus()" (focusout)="focusOut(true)"
               [ngClass]="{'uk-form-danger': checkFormValidity()}">
        <span class="uk-radio-label">{{ value }}</span>
      </label>
    </div>

    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}"
           [innerHTML]="fieldData.form.suggestion.text">
      </div>
    </div>
  </ng-template>
</form>
