<div class="uk-section-default uk-section uk-section-small" style="min-height: calc(100svh - var(--header-height));">
  <div *ngIf="userInfo" class="uk-container uk-container-small">

    <div class="uk-card uk-card-body uk-card-default">
      <h5 class="uk-flex uk-flex-middle">My profile
        <a *ngIf="!edit" class="material-icons uk-margin-small-left" title="Edit details" (click)="edit = true;">mode_edit</a>
        <a *ngIf="edit" class="material-icons uk-margin-small-left" title="Save changes" (click)="updateProfile()">save</a>
        <div class="uk-width-expand uk-flex uk-flex-right ">
          <a *ngIf="edit" class="material-icons uk-margin-small-left" title="Save changes" (click)="edit = false;">close</a>
        </div>
      </h5>
      <div class="uk-animation-scale-up" uk-grid>
        <div class="uk-width-1-4">
          <img *ngIf="!edit" class="uk-align-right circular-square"
               [src]="'data:image;base64,' + userInfo.user?.profile?.picture | safeUrl:'resourceUrl'">

          <div *ngIf="edit" class="uk-margin-top">
            <span class="">Upload a profile picture</span>
            <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg, image/webp" #fileSelect
                   (change)="onFileSelect($event)" style="display: none">
            <div class="uk-margin-small-left ">
              <a class="material-icons md-36" (click)="fileSelect.click()">upload_file</a>
              <span>{{image?.name ? image.name : 'No file selected.'}}</span>
            </div>
          </div>
        </div>
        <div class="uk-width-3-4 uk-margin-top">
          <div class="uk-margin-small">
            <span class="uk-text-bold">{{userInfo.user.surname + ' ' + userInfo.user.name}}</span>
            <div class="uk-text-muted">{{userInfo.user.email}}</div>
          </div>
          <div class="uk-margin-top" uk-grid>
            <div class="uk-width-1-2">
              <span class="uk-text-muted uk-flex uk-flex-bottom">Affiliation </span>
              <div *ngIf="!edit">{{userInfo.user?.profile?.affiliation ? userInfo.user?.profile?.affiliation : '--'}}</div>
              <input *ngIf="edit" class="uk-input uk-form-blank uk-animation-slide-left-small uk-width-small"
                     name="affiliation" id="affiliation" type="text" [(ngModel)]="userInfo.user.profile.affiliation">
            </div>
            <div class="uk-width-1-2">
              <span class="uk-text-muted uk-flex uk-flex-bottom">Position </span>
              <div *ngIf="!edit">{{userInfo.user?.profile?.position ? userInfo.user?.profile?.position : '--'}}</div>
              <input *ngIf="edit" class="uk-input uk-form-blank uk-animation-slide-left-small uk-width-small"
                     name="position" id="position" type="text" [(ngModel)]="userInfo.user.profile.position">
            </div>
            <div class="uk-width-1-1">
              <span class="uk-text-muted uk-flex uk-flex-bottom">Webpage </span>
              <a *ngIf="!edit" [href]="userInfo.user?.profile?.webpage" [ngClass]="{'uk-disabled': !userInfo.user?.profile?.webpage}" target="_blank">
                {{userInfo.user?.profile?.webpage ? userInfo.user?.profile?.webpage : '--'}}
              </a>
              <input *ngIf="edit" class="uk-input uk-form-blank uk-animation-slide-left-small"
                     name="position" id="webpage" type="text" [(ngModel)]="userInfo.user.profile.webpage">
            </div>
          </div>

        </div>

      </div>
    </div>
<!--    <pre>{{userInfo.user | json}}</pre>-->

  </div>
</div>
