<div class="uk-card uk-card-body uk-card-default uk-margin-medium-bottom">
  <div class="uk-margin">
    <label for="sectionName" class="uk-form-label uk-margin-small-bottom">Section Name *</label>
    <div class="uk-form-controls">
      <input id="sectionName" class="uk-input" [(ngModel)]="section.name" placeholder="Untitled Section">
    </div>
  </div>
  <div class="uk-margin">
    <label for="sectionDescription" class="uk-form-label uk-margin-small-bottom">Description *</label>
    <div class="uk-form-controls">
      <ckeditor id="sectionDescription" [editor]="editor"
                [(ngModel)]="section.description"
                [config]="{placeholder: 'Give a short description for the section'}">
      </ckeditor>
    </div>
  </div>
</div>
