<form [formGroup]="form" class="{{fieldData.display.cssClasses}}">
  <div class="uk-margin-bottom">
    <span *ngIf="fieldData.label.text" class="uk-form-label {{fieldData.label.cssClasses}}" [attr.for]="fieldData.name"
          style="{{fieldData.label.style}}" [innerHTML]="fieldData.label.text">
    </span>
    <span>{{fieldData.form.mandatory ? ' (*)' : ''}}</span>
  </div>
  <div *ngIf="fieldData.form.description.text" class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
       [innerHTML]="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
  </div>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity;" [formArrayName]="fieldData.name">
    <div *ngFor="let input of fieldAsFormArray().controls; let i=index" class="uk-margin-bottom uk-flex uk-flex-middle">
      <ng-select class="uk-width-9-10" [id]="fieldData.name" [formControlName]="i"
                 [ngClass]="{'uk-form-danger': checkFormArrayValidity(fieldData.name, i, this.editMode)}"
                 [items]="dynamicVoc?.length > 0 ? dynamicVoc :
                 fieldData.typeInfo.values ? fieldData.typeInfo.values : vocabularies[fieldData.typeInfo.vocabulary]"
                 (change)="updateBitSet(fieldData); unsavedChangesPrompt()"
                 bindLabel="name"
                 bindValue="id"
                 clearAllText="Clear">
      </ng-select>
      <a *ngIf="fieldAsFormArray().length > 1 && editMode"
         (click)="remove(fieldData.name, i); updateBitSet(fieldData); unsavedChangesPrompt()"
         class="remove-element uk-padding-small uk-animation-slide-top-small">
        <span class="material-icons">clear</span>
      </a>
    </div>
    <div><i><small>{{fieldData.form.suggestion.text}}</small></i></div>
    <div *ngIf="editMode" class="uk-width-9-10 uk-text-center uk-margin-top">
      <a class="add-new-element uk-flex uk-flex-middle uk-flex-center"
         (click)="push(fieldData.name, fieldData.form.mandatory, fieldData.typeInfo.type)">
        <span class="material-icons">add</span>Add &nbsp;<span [innerHTML]="fieldData.label.text"></span>
      </a>
    </div>
  </div>

  <ng-template #noMultiplicity>
    <ng-select class="uk-width-9-10 uk-margin-small" [id]="fieldData.name" [formControlName]="fieldData.name"
               (change)="updateBitSet(fieldData); unsavedChangesPrompt()"
               [ngClass]="{'uk-form-danger': checkFormValidity()}"
               [items]="dynamicVoc?.length > 0 ? dynamicVoc :
                 fieldData.typeInfo.values ? fieldData.typeInfo.values : vocabularies[fieldData.typeInfo.vocabulary]"
               bindLabel="name"
               bindValue="id"
               clearAllText="Clear">
    </ng-select>
    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}" [innerHTML]="fieldData.form.suggestion.text"></div>
    </div>
  </ng-template>

</form>
