<form [formGroup]="form" class="uk-margin-small-bottom {{fieldData.form.display.cssClasses}}" [ngClass]="{'uk-hidden clear-style': hideField}">

  <div class="uk-margin-bottom">
    <span *ngIf="fieldData.label.text" class="uk-form-label uk-width-9-10 {{fieldData.label.cssClasses}}"
          [attr.for]="fieldData.name" style="{{fieldData.label.style}}" [innerHTML]="fieldData.label.text">
    </span>
    <span>{{fieldData.form.mandatory ? ' (*)' : ''}}</span>
  </div>
  <ng-container *ngIf="fieldData.form.description?.showLess; else noReadMore">
    <div class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
         *ngIf="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
      <read-more-text [maxHeight]="46" [text]="fieldData.form.description.text"></read-more-text>
    </div>
  </ng-container>
  <ng-template #noReadMore>
    <div *ngIf="fieldData.form.description?.text" style="{{fieldData.form.description.style}}"
         class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
         [innerHTML]="fieldData.form.description.text | safeUrl: 'html'">
    </div>
  </ng-template>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity" [formArrayName]="fieldData.name">
    <div *ngFor="let input of fieldAsFormArray().controls; let i=index" class="uk-margin-bottom uk-flex uk-flex-middle">
      <div class="uk-margin-medium-top uk-width-9-10">
        <ckeditor [id]="inputId+'.['+i+']'" [formControlName]="i" [config]="{placeholder: fieldData.form.placeholder}"
                  (ngModelChange)="updateBitSet(fieldData)" [editor]="editor"
                  [ngClass]="{'uk-form-danger': checkFormValidity()}">
        </ckeditor>
      </div>
      <a *ngIf="fieldAsFormArray().length > 1 && editMode"
         (click)="remove(i); updateBitSet(fieldData); unsavedChangesPrompt()"
         class="remove-element uk-animation-slide-top-small uk-margin-small-left">
        <span class="material-icons">clear</span>
      </a>
    </div>
    <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}"
         [innerHTML]="fieldData.form.suggestion.text">
    </div>

    <div *ngIf="editMode" class="uk-width-9-10 uk-text-center uk-margin-top">
      <a class="add-new-element uk-flex uk-flex-middle uk-flex-center" (click)="push()">
        <span class="material-icons">add</span>Add &nbsp;<span [innerHTML]="fieldData.label.text"></span>
      </a>
    </div>
  </div>

  <ng-template #noMultiplicity>
    <div class="uk-margin-medium-top uk-width-9-10">
      <ckeditor [id]="inputId" [editor]="editor" [formControlName]="fieldData.name"
                (ngModelChange)="updateBitSet(fieldData)" [config]="{placeholder: fieldData.form.placeholder}"
                [ngClass]="{'uk-form-danger': checkFormValidity()}">
      </ckeditor>
    </div>
    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}" [innerHTML]="fieldData.form.suggestion.text"></div>
    </div>
  </ng-template>
</form>
