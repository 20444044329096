<div *ngIf="surveyAnswer" class="categories-content-tabs">

  <ul class="uk-subnav uk-subnav-pill categories-selection-tabs" uk-switcher>
    <li><a href="#">All</a></li>
    <li><a href="#">Publications</a></li>
    <li><a href="#">Data</a></li>
    <li><a href="#">Software</a></li>
    <li><a href="#">Services</a></li>
    <li><a href="#">Infrastructure</a></li>
    <li><a href="#">Skills/Training</a></li>
    <li><a href="#">Assessment</a></li>
    <li><a href="#">Engagement</a></li>
  </ul>

  <ul class="uk-switcher uk-margin">
    <li>
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">

            <table class="uk-table uk-table-divider uk-table-middle bordered-table">
              <thead>
              <tr>
                <th></th>
                <th>National policy</th>
                <th>Financial strategy</th>
                <th>RPOs with policy</th>
                <th>RFOs with Policy</th>
              </tr>
              </thead>
              <tbody>
<!--              <category-indicators-row [indicator1]="surveyAnswer['Policies']?.['Question6']?.['Question6-0']"-->
<!--                                       [indicator2]="surveyAnswer['Policies']?.['Question7']?.['Question7-0']"-->
<!--                                       [indicator3]="surveyAnswer['Policies']?.['Question8']?.['Question8-0']"-->
<!--                                       [indicator4]="surveyAnswer['Policies']?.['Question9']?.['Question9-0']">-->
<!--              </category-indicators-row>-->
              <tr>
                <td>
                  <div class="uk-text-bold">Publications</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question6']?.['Question6-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question6']?.['Question6-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question6']?.['Question6-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question7']?.['Question7-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question7']?.['Question7-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question7']?.['Question7-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question8']?.['Question8-0']">{{surveyAnswer['Policies']?.['Question8']?.['Question8-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question8']?.['Question8-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question9']?.['Question9-0']">{{surveyAnswer['Policies']?.['Question9']?.['Question9-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question9']?.['Question9-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Data</div>
                  <div class="uk-text-secondary uk-text-small uk-text-italic">Data management</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question10']?.['Question10-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question10']?.['Question10-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question10']?.['Question10-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question11']?.['Question11-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question11']?.['Question11-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question11']?.['Question11-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question12']?.['Question12-0']">{{surveyAnswer['Policies']?.['Question12']?.['Question12-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question12']?.['Question12-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question13']?.['Question13-0']">{{surveyAnswer['Policies']?.['Question13']?.['Question13-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question13']?.['Question13-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Data</div>
                  <div class="uk-text-secondary uk-text-small uk-text-italic">FAIR data</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question14']?.['Question14-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question14']?.['Question14-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question14']?.['Question14-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question15']?.['Question15-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question15']?.['Question15-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question15']?.['Question15-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question16']?.['Question16-0']">{{surveyAnswer['Policies']?.['Question16']?.['Question16-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question16']?.['Question16-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question17']?.['Question17-0']">{{surveyAnswer['Policies']?.['Question17']?.['Question17-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question17']?.['Question17-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Data</div>
                  <div class="uk-text-secondary uk-text-small uk-text-italic">Open Data</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question18']?.['Question18-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question18']?.['Question18-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question18']?.['Question18-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question19']?.['Question19-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question19']?.['Question19-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question19']?.['Question19-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question20']?.['Question20-0']">{{surveyAnswer['Policies']?.['Question20']?.['Question20-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question20']?.['Question20-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question21']?.['Question21-0']">{{surveyAnswer['Policies']?.['Question21']?.['Question21-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question21']?.['Question21-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Software</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question22']?.['Question22-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question22']?.['Question22-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question22']?.['Question22-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question23']?.['Question23-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question23']?.['Question23-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question23']?.['Question23-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question24']?.['Question24-0']">{{surveyAnswer['Policies']?.['Question24']?.['Question24-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question24']?.['Question24-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question25']?.['Question25-0']">{{surveyAnswer['Policies']?.['Question25']?.['Question25-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question25']?.['Question25-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Services</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question26']?.['Question26-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question26']?.['Question26-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question26']?.['Question26-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question27']?.['Question27-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question27']?.['Question27-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question27']?.['Question27-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question28']?.['Question28-0']">{{surveyAnswer['Policies']?.['Question28']?.['Question28-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question28']?.['Question28-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question29']?.['Question29-0']">{{surveyAnswer['Policies']?.['Question29']?.['Question29-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question29']?.['Question29-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Infrastructure</div>
                  <div class="uk-text-secondary uk-text-small uk-text-italic">Connecting repositories to EOSC</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question30']?.['Question30-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question30']?.['Question30-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question30']?.['Question30-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question31']?.['Question31-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question31']?.['Question31-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question31']?.['Question31-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question32']?.['Question32-0']">{{surveyAnswer['Policies']?.['Question32']?.['Question32-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question32']?.['Question32-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question33']?.['Question33-0']">{{surveyAnswer['Policies']?.['Question33']?.['Question33-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question33']?.['Question33-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Infrastructure</div>
                  <div class="uk-text-secondary uk-text-small uk-text-italic">Data stewardship</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question34']?.['Question34-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question34']?.['Question34-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question34']?.['Question34-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question35']?.['Question35-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question35']?.['Question35-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question35']?.['Question35-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question36']?.['Question36-0']">{{surveyAnswer['Policies']?.['Question36']?.['Question36-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question36']?.['Question36-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question37']?.['Question37-0']">{{surveyAnswer['Policies']?.['Question37']?.['Question37-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question37']?.['Question37-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Infrastructure</div>
                  <div class="uk-text-secondary uk-text-small uk-text-italic">Long-term data preservation</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question38']?.['Question38-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question38']?.['Question38-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question38']?.['Question38-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question39']?.['Question39-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question39']?.['Question39-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question39']?.['Question39-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question40']?.['Question40-0']">{{surveyAnswer['Policies']?.['Question40']?.['Question40-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question40']?.['Question40-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question41']?.['Question41-0']">{{surveyAnswer['Policies']?.['Question41']?.['Question41-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question41']?.['Question41-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Skills/Training</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question42']?.['Question42-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question42']?.['Question42-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question42']?.['Question42-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question43']?.['Question43-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question43']?.['Question43-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question43']?.['Question43-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question44']?.['Question44-0']">{{surveyAnswer['Policies']?.['Question44']?.['Question44-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question44']?.['Question44-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question45']?.['Question45-0']">{{surveyAnswer['Policies']?.['Question45']?.['Question45-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question45']?.['Question45-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Assessment</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question46']?.['Question46-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question46']?.['Question46-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question46']?.['Question46-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question47']?.['Question47-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question47']?.['Question47-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question47']?.['Question47-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question48']?.['Question48-0']">{{surveyAnswer['Policies']?.['Question48']?.['Question48-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question48']?.['Question48-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question49']?.['Question49-0']">{{surveyAnswer['Policies']?.['Question49']?.['Question49-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question49']?.['Question49-0']">N/A</ng-container>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="uk-text-bold">Engagement</div>
                </td>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question50']?.['Question50-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question50']?.['Question50-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question50']?.['Question50-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <ng-container *ngIf="surveyAnswer['Policies']?.['Question51']?.['Question51-0']">
                  <td [ngClass]="surveyAnswer['Policies']?.['Question51']?.['Question51-0'] == 'Yes'? 'valueTrue' : 'valueFalse'"></td>
                </ng-container>
                <ng-container *ngIf="!surveyAnswer['Policies']?.['Question51']?.['Question51-0']">
                  <td class="valueNotAvailable"></td>
                </ng-container>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question52']?.['Question52-0']">{{surveyAnswer['Policies']?.['Question52']?.['Question52-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question52']?.['Question52-0']">N/A</ng-container>
                </td>
                <td>
                  <ng-container *ngIf="surveyAnswer['Policies']?.['Question53']?.['Question53-0']">{{surveyAnswer['Policies']?.['Question53']?.['Question53-0']}}</ng-container>
                  <ng-container *ngIf="!surveyAnswer['Policies']?.['Question53']?.['Question53-0']">N/A</ng-container>
                </td>
              </tr>
              </tbody>
            </table>

<!--            &lt;!&ndash;          Publications&ndash;&gt;-->
<!--            <div class="uk-grid uk-child-width-1-2">-->
<!--              <div>-->
<!--                <category-indicators-wrapper [cardTitle]="'Publications'"-->
<!--                                             [cardIcon]="'../assets/images/country_pages/Icon%20awesome-file-alt.svg'">-->
<!--                  <policies-category-indicators [nationalPolicy]="surveyAnswer['Policies']?.['Question6']?.['Question6-0']"-->
<!--                                                [financialStrategy]="surveyAnswer['Policies']?.['Question7']?.['Question7-0']"-->
<!--                                                [rpos]="surveyAnswer['Policies']?.['Question8']?.['Question8-0']"-->
<!--                                                [rfos]="surveyAnswer['Policies']?.['Question9']?.['Question9-0']">-->
<!--                  </policies-category-indicators>-->
<!--                </category-indicators-wrapper>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;          Data&ndash;&gt;-->
<!--            <div class="uk-grid uk-child-width-1-1">-->
<!--              <div>-->
<!--                <category-indicators-wrapper [hasSubcategories]="true" [cardTitle]="'Data'"-->
<!--                                             [cardIcon]="'../assets/images/country_pages/Icon%20awesome-database.svg'">-->
<!--                  <policies-category-indicators [subtitle]="'Data management'"-->
<!--                                                [nationalPolicy]="surveyAnswer['Policies']?.['Question10']?.['Question10-0']"-->
<!--                                                [financialStrategy]="surveyAnswer['Policies']?.['Question11']?.['Question11-0']"-->
<!--                                                [rpos]="surveyAnswer['Policies']?.['Question12']?.['Question12-0']"-->
<!--                                                [rfos]="surveyAnswer['Policies']?.['Question13']?.['Question13-0']">-->
<!--                  </policies-category-indicators>-->
<!--                  <policies-category-indicators [subtitle]="'FAIR data'"-->
<!--                                                [nationalPolicy]="surveyAnswer['Policies']?.['Question14']?.['Question14-0']"-->
<!--                                                [financialStrategy]="surveyAnswer['Policies']?.['Question15']?.['Question15-0']"-->
<!--                                                [rpos]="surveyAnswer['Policies']?.['Question16']?.['Question16-0']"-->
<!--                                                [rfos]="surveyAnswer['Policies']?.['Question17']?.['Question17-0']">-->
<!--                  </policies-category-indicators>-->
<!--                  <policies-category-indicators [subtitle]="'Open data'"-->
<!--                                                [nationalPolicy]="surveyAnswer['Policies']?.['Question18']?.['Question18-0']"-->
<!--                                                [financialStrategy]="surveyAnswer['Policies']?.['Question19']?.['Question19-0']"-->
<!--                                                [rpos]="surveyAnswer['Policies']?.['Question20']?.['Question20-0']"-->
<!--                                                [rfos]="surveyAnswer['Policies']?.['Question21']?.['Question21-0']">-->
<!--                  </policies-category-indicators>-->
<!--                </category-indicators-wrapper>-->
<!--              </div>-->
<!--            </div>-->

<!--&lt;!&ndash;            Services & Software&ndash;&gt;-->
<!--            <div class="uk-grid uk-child-width-1-2">-->
<!--              <div>-->
<!--                <category-indicators-wrapper [cardTitle]="'Software'"-->
<!--                                             [cardIcon]="'../assets/images/country_pages/noun-software-2822919.svg'">-->
<!--                  <policies-category-indicators-->
<!--                    [nationalPolicy]="surveyAnswer['Policies']?.['Question22']?.['Question22-0']"-->
<!--                    [financialStrategy]="surveyAnswer['Policies']?.['Question23']?.['Question23-0']"-->
<!--                    [rpos]="surveyAnswer['Policies']?.['Question24']?.['Question24-0']"-->
<!--                    [rfos]="surveyAnswer['Policies']?.['Question25']?.['Question25-0']">-->
<!--                  </policies-category-indicators>-->
<!--                </category-indicators-wrapper>-->
<!--              </div>-->
<!--              <div>-->
<!--                <category-indicators-wrapper [cardTitle]="'Services'"-->
<!--                                             [cardIcon]="'../assets/images/country_pages/noun-settings-5110695.svg'">-->
<!--                  <policies-category-indicators-->
<!--                    [nationalPolicy]="surveyAnswer['Policies']?.['Question26']?.['Question26-0']"-->
<!--                    [financialStrategy]="surveyAnswer['Policies']?.['Question27']?.['Question27-0']"-->
<!--                    [rpos]="surveyAnswer['Policies']?.['Question28']?.['Question28-0']"-->
<!--                    [rfos]="surveyAnswer['Policies']?.['Question29']?.['Question29-0']">-->
<!--                  </policies-category-indicators>-->
<!--                </category-indicators-wrapper>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;          Infrastructure&ndash;&gt;-->
<!--            <div class="uk-grid uk-child-width-1-1">-->
<!--              <div>-->
<!--                <category-indicators-wrapper [hasSubcategories]="true" [cardTitle]="'Infrastructure'"-->
<!--                                             [cardIcon]="'../assets/images/country_pages/noun-infrastructure-1694567.svg'">-->
<!--                  <policies-category-indicators [subtitle]="'Connecting repositories to EOSC'"-->
<!--                    [nationalPolicy]="surveyAnswer['Policies']?.['Question30']?.['Question30-0']"-->
<!--                    [financialStrategy]="surveyAnswer['Policies']?.['Question31']?.['Question31-0']"-->
<!--                    [rpos]="surveyAnswer['Policies']?.['Question32']?.['Question32-0']"-->
<!--                    [rfos]="surveyAnswer['Policies']?.['Question33']?.['Question33-0']">-->
<!--                  </policies-category-indicators>-->
<!--                  <policies-category-indicators [subtitle]="'Data stewardship'"-->
<!--                    [nationalPolicy]="surveyAnswer['Policies']?.['Question34']?.['Question34-0']"-->
<!--                    [financialStrategy]="surveyAnswer['Policies']?.['Question35']?.['Question35-0']"-->
<!--                    [rpos]="surveyAnswer['Policies']?.['Question36']?.['Question36-0']"-->
<!--                    [rfos]="surveyAnswer['Policies']?.['Question37']?.['Question37-0']">-->
<!--                  </policies-category-indicators>-->
<!--                  <policies-category-indicators-->
<!--                    [subtitle]="'Long-term data preservation'"-->
<!--                    [nationalPolicy]="surveyAnswer['Policies']?.['Question38']?.['Question38-0']"-->
<!--                    [financialStrategy]="surveyAnswer['Policies']?.['Question39']?.['Question39-0']"-->
<!--                    [rpos]="surveyAnswer['Policies']?.['Question40']?.['Question40-0']"-->
<!--                    [rfos]="surveyAnswer['Policies']?.['Question41']?.['Question41-0']">-->
<!--                  </policies-category-indicators>-->
<!--                </category-indicators-wrapper>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;            Skills/Training & Assessment&ndash;&gt;-->
<!--            <div class="uk-grid uk-child-width-1-2">-->
<!--              <div>-->
<!--                <category-indicators-wrapper [cardTitle]="'Skills/Training'"-->
<!--                                             [cardIcon]="'../assets/images/country_pages/noun-skills-2129400.svg'">-->
<!--                  <policies-category-indicators-->
<!--                    [nationalPolicy]="surveyAnswer['Policies']?.['Question42']?.['Question42-0']"-->
<!--                    [financialStrategy]="surveyAnswer['Policies']?.['Question43']?.['Question43-0']"-->
<!--                    [rpos]="surveyAnswer['Policies']?.['Question44']?.['Question44-0']"-->
<!--                    [rfos]="surveyAnswer['Policies']?.['Question45']?.['Question45-0']">-->
<!--                  </policies-category-indicators>-->
<!--                </category-indicators-wrapper>-->
<!--              </div>-->
<!--              <div>-->
<!--                <category-indicators-wrapper [cardTitle]="'Assessment'"-->
<!--                                             [cardIcon]="'../assets/images/country_pages/noun-assesment-4658555.svg'">-->
<!--                  <policies-category-indicators-->
<!--                    [nationalPolicy]="surveyAnswer['Policies']?.['Question46']?.['Question46-0']"-->
<!--                    [financialStrategy]="surveyAnswer['Policies']?.['Question47']?.['Question47-0']"-->
<!--                    [rpos]="surveyAnswer['Policies']?.['Question48']?.['Question48-0']"-->
<!--                    [rfos]="surveyAnswer['Policies']?.['Question49']?.['Question49-0']">-->
<!--                  </policies-category-indicators>-->
<!--                </category-indicators-wrapper>-->
<!--              </div>-->
<!--            </div>-->

<!--            &lt;!&ndash;          Engagement&ndash;&gt;-->
<!--            <div class="uk-grid uk-child-width-1-2">-->
<!--              <div>-->
<!--                <category-indicators-wrapper [cardTitle]="'Engagement'"-->
<!--                                             [cardIcon]="'../assets/images/country_pages/noun-work-engagement-946691.svg'">-->
<!--                  <policies-category-indicators-->
<!--                    [nationalPolicy]="surveyAnswer['Policies']?.['Question50']?.['Question50-0']"-->
<!--                    [financialStrategy]="surveyAnswer['Policies']?.['Question51']?.['Question51-0']"-->
<!--                    [rpos]="surveyAnswer['Policies']?.['Question52']?.['Question52-0']"-->
<!--                    [rfos]="surveyAnswer['Policies']?.['Question53']?.['Question53-0']">-->
<!--                  </policies-category-indicators>-->
<!--                </category-indicators-wrapper>-->
<!--              </div>-->
<!--            </div>-->

          </div>

<!--          Comments....-->
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Publications-->
              <li *ngIf="surveyAnswer['Policies']?.['Question6']?.['Question6-6']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on open access to publications</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question6']?.['Question6-6']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question7']?.['Question7-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on open access to publications</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question7']?.['Question7-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question8']?.['Question8-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on open access to publications</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question8']?.['Question8-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question9']?.['Question9-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on open access</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question9']?.['Question9-1']}}</p>
                </div>
              </li>
              <!--              Data management-->
              <li *ngIf="surveyAnswer['Policies']?.['Question10']?.['Question10-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on data management</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question10']?.['Question10-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question11']?.['Question11-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on data management</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question11']?.['Question11-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question12']?.['Question12-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on data management</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question12']?.['Question12-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question13']?.['Question13-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on data management</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question13']?.['Question13-1']}}</p>
                </div>
              </li>
              <!--              FAIR Data-->
              <li *ngIf="surveyAnswer['Policies']?.['Question14']?.['Question14-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on FAIR data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question14']?.['Question14-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question15']?.['Question15-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on FAIR data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question15']?.['Question15-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question16']?.['Question16-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on FAIR data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question16']?.['Question16-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question17']?.['Question17-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on FAIR data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question17']?.['Question17-1']}}</p>
                </div>
              </li>
              <!--              Open Data-->
              <li *ngIf="surveyAnswer['Policies']?.['Question18']?.['Question18-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on open data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question18']?.['Question18-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question19']?.['Question19-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on open data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question19']?.['Question19-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question20']?.['Question20-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on open data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question20']?.['Question20-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question21']?.['Question21-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on open data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question21']?.['Question21-1']}}</p>
                </div>
              </li>
              <!--              Software-->
              <li *ngIf="surveyAnswer['Policies']?.['Question22']?.['Question22-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on open source software</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question22']?.['Question22-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question23']?.['Question23-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on open source software</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question23']?.['Question23-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question24']?.['Question24-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on open source software</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question24']?.['Question24-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question25']?.['Question25-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on open source software</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question25']?.['Question25-1']}}</p>
                </div>
              </li>
              <!--              Services-->
              <li *ngIf="surveyAnswer['Policies']?.['Question26']?.['Question26-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on offering services through EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question26']?.['Question26-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question27']?.['Question27-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on offering services through EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question27']?.['Question27-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question28']?.['Question28-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on offering services through EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question28']?.['Question28-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question29']?.['Question29-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on offering services through EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question29']?.['Question29-1']}}</p>
                </div>
              </li>
              <!--              Connecting repositories-->
              <li *ngIf="surveyAnswer['Policies']?.['Question30']?.['Question30-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on connecting repositories to EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question30']?.['Question30-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question31']?.['Question31-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on connecting repositories to EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question31']?.['Question31-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question32']?.['Question32-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on connecting repositories to EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question32']?.['Question32-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question33']?.['Question33-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on connecting repositories to EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question33']?.['Question33-1']}}</p>
                </div>
              </li>
              <!--              Data stewardship-->
              <li *ngIf="surveyAnswer['Policies']?.['Question34']?.['Question34-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on data stewardship</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question34']?.['Question34-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question35']?.['Question35-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on data stewardship</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question35']?.['Question35-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question36']?.['Question36-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on data stewardship</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question36']?.['Question36-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question37']?.['Question37-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on data stewardship</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question37']?.['Question37-1']}}</p>
                </div>
              </li>
              <!--              Long-term data preservation-->
              <li *ngIf="surveyAnswer['Policies']?.['Question38']?.['Question38-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on long-term data preservation</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question38']?.['Question38-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question39']?.['Question39-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on long-term data preservation</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question39']?.['Question39-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question40']?.['Question40-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on long-term data preservation</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question40']?.['Question40-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question41']?.['Question41-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on long-term data preservation</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question41']?.['Question41-1']}}</p>
                </div>
              </li>
              <!--              Skills/Training-->
              <li *ngIf="surveyAnswer['Policies']?.['Question42']?.['Question42-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on skills/training for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question42']?.['Question42-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question43']?.['Question43-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on skills/training for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question43']?.['Question43-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question44']?.['Question44-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on skills/training for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question44']?.['Question44-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question45']?.['Question45-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on skills/training for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question45']?.['Question45-1']}}</p>
                </div>
              </li>
              <!--              Assessment-->
              <li *ngIf="surveyAnswer['Policies']?.['Question46']?.['Question46-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on incentives/rewards for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question46']?.['Question46-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question47']?.['Question47-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on incentives/rewards for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question47']?.['Question47-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question48']?.['Question48-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on incentives/rewards for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question48']?.['Question48-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question49']?.['Question49-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on incentives/rewards for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question49']?.['Question49-1']}}</p>
                </div>
              </li>
              <!--              Engagement-->
              <li *ngIf="surveyAnswer['Policies']?.['Question50']?.['Question50-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on citizen science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question50']?.['Question50-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question51']?.['Question51-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on citizen science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question51']?.['Question51-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question52']?.['Question52-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on citizen science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question52']?.['Question52-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question53']?.['Question53-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on citizen science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question53']?.['Question53-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">
            <!--          Publications-->
            <div class="uk-grid uk-child-width-1-2">
              <div>
                <category-indicators-wrapper [cardTitle]="'Publications'"
                                             [cardIcon]="'../assets/images/country_pages/Icon%20awesome-file-alt.svg'">
                  <policies-category-indicators [nationalPolicy]="surveyAnswer['Policies']?.['Question6']?.['Question6-0']"
                                                [financialStrategy]="surveyAnswer['Policies']?.['Question7']?.['Question7-0']"
                                                [rpos]="surveyAnswer['Policies']?.['Question8']?.['Question8-0']"
                                                [rfos]="surveyAnswer['Policies']?.['Question9']?.['Question9-0']">
                  </policies-category-indicators>
                </category-indicators-wrapper>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Publications-->
              <li *ngIf="surveyAnswer['Policies']?.['Question6']?.['Question6-6']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on open access to publications</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question6']?.['Question6-6']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question7']?.['Question7-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on open access to publications</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question7']?.['Question7-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question8']?.['Question8-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on open access to publications</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question8']?.['Question8-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question9']?.['Question9-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on open access</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question9']?.['Question9-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">
            <!--          Data-->
            <div class="uk-grid uk-child-width-1-1">
              <div>
                <category-indicators-wrapper [hasSubcategories]="true" [cardTitle]="'Data'"
                                             [cardIcon]="'../assets/images/country_pages/Icon%20awesome-database.svg'">
                  <policies-category-indicators [subtitle]="'Data management'"
                                                [nationalPolicy]="surveyAnswer['Policies']?.['Question10']?.['Question10-0']"
                                                [financialStrategy]="surveyAnswer['Policies']?.['Question11']?.['Question11-0']"
                                                [rpos]="surveyAnswer['Policies']?.['Question12']?.['Question12-0']"
                                                [rfos]="surveyAnswer['Policies']?.['Question13']?.['Question13-0']">
                  </policies-category-indicators>
                  <policies-category-indicators [subtitle]="'FAIR data'"
                                                [nationalPolicy]="surveyAnswer['Policies']?.['Question14']?.['Question14-0']"
                                                [financialStrategy]="surveyAnswer['Policies']?.['Question15']?.['Question15-0']"
                                                [rpos]="surveyAnswer['Policies']?.['Question16']?.['Question16-0']"
                                                [rfos]="surveyAnswer['Policies']?.['Question17']?.['Question17-0']">
                  </policies-category-indicators>
                  <policies-category-indicators [subtitle]="'Open data'"
                                                [nationalPolicy]="surveyAnswer['Policies']?.['Question18']?.['Question18-0']"
                                                [financialStrategy]="surveyAnswer['Policies']?.['Question19']?.['Question19-0']"
                                                [rpos]="surveyAnswer['Policies']?.['Question20']?.['Question20-0']"
                                                [rfos]="surveyAnswer['Policies']?.['Question21']?.['Question21-0']">
                  </policies-category-indicators>
                </category-indicators-wrapper>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Data management-->
              <li *ngIf="surveyAnswer['Policies']?.['Question10']?.['Question10-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on data management</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question10']?.['Question10-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question11']?.['Question11-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on data management</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question11']?.['Question11-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question12']?.['Question12-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on data management</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question12']?.['Question12-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question13']?.['Question13-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on data management</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question13']?.['Question13-1']}}</p>
                </div>
              </li>
              <!--              FAIR Data-->
              <li *ngIf="surveyAnswer['Policies']?.['Question14']?.['Question14-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on FAIR data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question14']?.['Question14-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question15']?.['Question15-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on FAIR data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question15']?.['Question15-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question16']?.['Question16-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on FAIR data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question16']?.['Question16-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question17']?.['Question17-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on FAIR data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question17']?.['Question17-1']}}</p>
                </div>
              </li>
              <!--              Open Data-->
              <li *ngIf="surveyAnswer['Policies']?.['Question18']?.['Question18-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on open data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question18']?.['Question18-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question19']?.['Question19-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on open data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question19']?.['Question19-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question20']?.['Question20-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on open data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question20']?.['Question20-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question21']?.['Question21-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on open data</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question21']?.['Question21-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li>
<!--      Software content-->
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">
            <div class="uk-grid uk-child-width-1-2">
              <div>
                <category-indicators-wrapper [cardTitle]="'Software'"
                                             [cardIcon]="'../assets/images/country_pages/noun-software-2822919.svg'">
                  <policies-category-indicators
                    [nationalPolicy]="surveyAnswer['Policies']?.['Question22']?.['Question22-0']"
                    [financialStrategy]="surveyAnswer['Policies']?.['Question23']?.['Question23-0']"
                    [rpos]="surveyAnswer['Policies']?.['Question24']?.['Question24-0']"
                    [rfos]="surveyAnswer['Policies']?.['Question25']?.['Question25-0']">
                  </policies-category-indicators>
                </category-indicators-wrapper>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Software-->
              <li *ngIf="surveyAnswer['Policies']?.['Question22']?.['Question22-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on open source software</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question22']?.['Question22-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question23']?.['Question23-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on open source software</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question23']?.['Question23-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question24']?.['Question24-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on open source software</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question24']?.['Question24-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question25']?.['Question25-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on open source software</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question25']?.['Question25-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li>
<!--      Services content-->
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">
            <div class="uk-grid uk-child-width-1-2">
              <div>
                <category-indicators-wrapper [cardTitle]="'Services'"
                                             [cardIcon]="'../assets/images/country_pages/noun-settings-5110695.svg'">
                  <policies-category-indicators [nationalPolicy]="'Yes'" [financialStrategy]="'No'"
                                                [rpos]="surveyAnswer['Practices']?.['Question56']?.['Question56-0']" [rfos]="'0'">
                  </policies-category-indicators>
                </category-indicators-wrapper>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Services-->
              <li *ngIf="surveyAnswer['Policies']?.['Question26']?.['Question26-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on offering services through EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question26']?.['Question26-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question27']?.['Question27-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on offering services through EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question27']?.['Question27-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question28']?.['Question28-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on offering services through EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question28']?.['Question28-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question29']?.['Question29-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on offering services through EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question29']?.['Question29-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">
            <!--          Infrastructure-->
            <div class="uk-grid uk-child-width-1-1">
              <div>
                <category-indicators-wrapper [hasSubcategories]="true" [cardTitle]="'Infrastructure'"
                                             [cardIcon]="'../assets/images/country_pages/noun-infrastructure-1694567.svg'">
                  <policies-category-indicators
                    [subtitle]="'Connecting repositories to EOSC'"
                    [nationalPolicy]="surveyAnswer['Policies']?.['Question30']?.['Question30-0']"
                    [financialStrategy]="surveyAnswer['Policies']?.['Question31']?.['Question31-0']"
                    [rpos]="surveyAnswer['Policies']?.['Question32']?.['Question32-0']"
                    [rfos]="surveyAnswer['Policies']?.['Question33']?.['Question33-0']">
                  </policies-category-indicators>
                  <policies-category-indicators
                    [subtitle]="'Data stewardship'"
                    [nationalPolicy]="surveyAnswer['Policies']?.['Question34']?.['Question34-0']"
                    [financialStrategy]="surveyAnswer['Policies']?.['Question35']?.['Question35-0']"
                    [rpos]="surveyAnswer['Policies']?.['Question36']?.['Question36-0']"
                    [rfos]="surveyAnswer['Policies']?.['Question37']?.['Question37-0']">
                  </policies-category-indicators>
                  <policies-category-indicators
                    [subtitle]="'Long-term data preservation'"
                    [nationalPolicy]="surveyAnswer['Policies']?.['Question38']?.['Question38-0']"
                    [financialStrategy]="surveyAnswer['Policies']?.['Question39']?.['Question39-0']"
                    [rpos]="surveyAnswer['Policies']?.['Question40']?.['Question40-0']"
                    [rfos]="surveyAnswer['Policies']?.['Question41']?.['Question41-0']">
                  </policies-category-indicators>
                </category-indicators-wrapper>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Connecting repositories-->
              <li *ngIf="surveyAnswer['Policies']?.['Question30']?.['Question30-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on connecting repositories to EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question30']?.['Question30-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question31']?.['Question31-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on connecting repositories to EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question31']?.['Question31-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question32']?.['Question32-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on connecting repositories to EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question32']?.['Question32-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question33']?.['Question33-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on connecting repositories to EOSC</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question33']?.['Question33-1']}}</p>
                </div>
              </li>
              <!--              Data stewardship-->
              <li *ngIf="surveyAnswer['Policies']?.['Question34']?.['Question34-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on data stewardship</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question34']?.['Question34-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question35']?.['Question35-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on data stewardship</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question35']?.['Question35-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question36']?.['Question36-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on data stewardship</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question36']?.['Question36-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question37']?.['Question37-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on data stewardship</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question37']?.['Question37-1']}}</p>
                </div>
              </li>
              <!--              Long-term data preservation-->
              <li *ngIf="surveyAnswer['Policies']?.['Question38']?.['Question38-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on long-term data preservation</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question38']?.['Question38-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question39']?.['Question39-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on long-term data preservation</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question39']?.['Question39-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question40']?.['Question40-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on long-term data preservation</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question40']?.['Question40-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question41']?.['Question41-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on long-term data preservation</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question41']?.['Question41-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li>
<!--      Skills/Training content-->
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">
            <div class="uk-grid uk-child-width-1-2">
              <div>
                <category-indicators-wrapper [cardTitle]="'Skills/Training'"
                                             [cardIcon]="'../assets/images/country_pages/noun-skills-2129400.svg'">
                  <policies-category-indicators
                    [nationalPolicy]="surveyAnswer['Policies']?.['Question42']?.['Question42-0']"
                    [financialStrategy]="surveyAnswer['Policies']?.['Question43']?.['Question43-0']"
                    [rpos]="surveyAnswer['Policies']?.['Question44']?.['Question44-0']"
                    [rfos]="surveyAnswer['Policies']?.['Question45']?.['Question45-0']">
                  </policies-category-indicators>
                </category-indicators-wrapper>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Skills/Training-->
              <li *ngIf="surveyAnswer['Policies']?.['Question42']?.['Question42-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on skills/training for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question42']?.['Question42-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question43']?.['Question43-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on skills/training for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question43']?.['Question43-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question44']?.['Question44-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on skills/training for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question44']?.['Question44-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question45']?.['Question45-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on skills/training for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question45']?.['Question45-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li>
<!--      Assessment content-->
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">
            <div class="uk-grid uk-child-width-1-2">
              <div>
                <category-indicators-wrapper [cardTitle]="'Assessment'"
                                             [cardIcon]="'../assets/images/country_pages/noun-assesment-4658555.svg'">
                  <policies-category-indicators
                    [nationalPolicy]="surveyAnswer['Policies']?.['Question46']?.['Question46-0']"
                    [financialStrategy]="surveyAnswer['Policies']?.['Question47']?.['Question47-0']"
                    [rpos]="surveyAnswer['Policies']?.['Question48']?.['Question48-0']"
                    [rfos]="surveyAnswer['Policies']?.['Question49']?.['Question49-0']">
                  </policies-category-indicators>
                </category-indicators-wrapper>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Assessment-->
              <li *ngIf="surveyAnswer['Policies']?.['Question46']?.['Question46-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on incentives/rewards for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question46']?.['Question46-3']}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question47']?.['Question47-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on incentives/rewards for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question47']?.['Question47-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question48']?.['Question48-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on incentives/rewards for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question48']?.['Question48-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question49']?.['Question49-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on incentives/rewards for Open Science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question49']?.['Question49-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="uk-margin-medium-top">
        <div class="uk-grid uk-grid-divider">
          <div class="uk-width-2-3">
            <!--          Engagement-->
            <div class="uk-grid uk-child-width-1-2">
              <div>
                <category-indicators-wrapper [cardTitle]="'Engagement'"
                                             [cardIcon]="'../assets/images/country_pages/noun-work-engagement-946691.svg'">
                  <policies-category-indicators
                    [nationalPolicy]="surveyAnswer['Policies']?.['Question50']?.['Question50-0']"
                    [financialStrategy]="surveyAnswer['Policies']?.['Question51']?.['Question51-0']"
                    [rpos]="surveyAnswer['Policies']?.['Question52']?.['Question52-0']"
                    [rfos]="surveyAnswer['Policies']?.['Question53']?.['Question53-0']">
                  </policies-category-indicators>
                </category-indicators-wrapper>
              </div>
            </div>
          </div>
          <div class="uk-width-1-3">
            <ul uk-accordion="collapsible: false" class="uk-text-small">
              <!--              Engagement-->
              <li *ngIf="surveyAnswer['Policies']?.['Question50']?.['Question50-3']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">National policy on citizen science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question50']?.['Question50-3']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question51']?.['Question51-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">Financial strategy on citizen science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question51']?.['Question51-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question52']?.['Question52-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RPOs with policy on citizen science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question52']?.['Question52-1']}}</p>
                </div>
              </li>
              <li *ngIf="surveyAnswer['Policies']?.['Question53']?.['Question53-1']" class="uk-margin-medium-bottom">
                <a class="uk-accordion-title" href>
                  <span class="comment-number uk-margin-small-right">-</span>
                  <span class="comment-title">RFOs with policy on citizen science</span>
                </a>
                <div class="uk-accordion-content">
                  <p>{{surveyAnswer['Policies']?.['Question53']?.['Question53-1']}}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

