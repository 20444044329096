export const countries = [
  {id : 'AD', name : 'Andorra'},
  {id : 'AL', name : 'Albania'},
  {id : 'AM', name : 'Armenia'},
  {id : 'AT', name : 'Austria'},
  {id : 'AZ', name : 'Azerbaijan'},
  {id : 'BY', name : 'Belarus'},
  {id : 'BE', name : 'Belgium'},
  {id : 'BA', name : 'Bosnia and Herzegovina'},
  {id : 'BG', name : 'Bulgaria'},
  {id : 'HR', name : 'Croatia'},
  {id : 'CZ', name : 'Czech Republic'},
  {id : 'CY', name : 'Cyprus'},
  {id : 'DK', name : 'Denmark'},
  {id : 'EE', name : 'Estonia'},
  {id : 'FO', name : 'Faroe Islands'},
  {id : 'FI', name : 'Finland'},
  {id : 'FR', name : 'France'},
  {id : 'DE', name : 'Germany'},
  // {id : 'GI', name : 'Gibraltar'},
  {id : 'GE', name : 'Georgia'},
  {id : 'GR', name : 'Greece'},
  // {id : 'GG', name : 'Guernsey'},
  // {id : 'VA', name : 'Holy See (Vatican City State)'},
  {id : 'HU', name : 'Hungary'},
  {id : 'IS', name : 'Iceland'},
  {id : 'IE', name : 'Ireland'},
  {id : 'IL', name : 'Israel'},
  // {id : 'IM', name : 'Isle of Man'},
  {id : 'IT', name : 'Italy'},
  // {id : 'JE', name : 'Jersey'},
  {id : 'LV', name : 'Latvia'},
  // {id : 'LI', name : 'Liechtenstein'},
  {id : 'LT', name : 'Lithuania'},
  {id : 'LU', name : 'Luxembourg'},
  {id : 'MT', name : 'Malta'},
  {id : 'MD', name : 'Moldova, Republic of'},
  // {id : 'MC', name : 'Monaco'},
  {id : 'ME', name : 'Montenegro'},
  {id : 'NL', name : 'Netherlands'},
  {id : 'MK', name : 'North Macedonia'},
  {id : 'NO', name : 'Norway'},
  {id : 'PL', name : 'Poland'},
  {id : 'PT', name : 'Portugal'},
  {id : 'RO', name : 'Romania'},
  {id : 'RU', name : 'Russian Federation'},
  // {id : 'SM', name : 'San Marino'},
  {id : 'RS', name : 'Serbia'},
  {id : 'SK', name : 'Slovakia'},
  {id : 'SI', name : 'Slovenia'},
  {id : 'ES', name : 'Spain'},
  // {id : 'SJ', name : 'Svalbard and Jan Mayen'},
  {id : 'SE', name : 'Sweden'},
  {id : 'CH', name : 'Switzerland'},
  {id : 'TR', name : 'Turkey'},
  {id : 'UA', name : 'Ukraine'},
  {id : 'GB', name : 'United Kingdom'},
  // {id : 'AX', name : 'Åland Islands'}
];
