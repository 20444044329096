<form [formGroup]="form" class="uk-margin-small-bottom {{fieldData.form.display.cssClasses}}"
      [ngClass]="{'uk-hidden clear-style': (hideField || !fieldData.form.display.visible)}">
  <div class="uk-margin-bottom">
    <span *ngIf="fieldData.label.text" class="uk-form-label {{fieldData.label.cssClasses}}" [attr.for]="fieldData.name"
          [ngClass]="{'uk-subLabel': fieldData.form.dependsOn !== null}" style="{{fieldData.label.style}}"
          [innerHTML]="fieldData.label.text">
    </span>
    <span>{{fieldData.form.mandatory ? ' (*)' : ''}}</span>
  </div>
  <div *ngIf="fieldData.form.description?.text" class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
       [innerHTML]="fieldData.form.description.text | safeUrl: 'html'" style="{{fieldData.form.description.style}}">
  </div>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity" [formArrayName]="fieldData.name">
    <div appSortable (positionChanged)="onPositionChanged($event)" id="sortable-container">
      <div *ngFor="let input of fieldAsFormArray().controls; let i=index" id="{{i}}" [attr.data-index]="i"
           class="uk-margin-bottom uk-flex uk-flex-middle">
        <input [id]="inputId+'.['+i+']'" [formControlName]="i" class="uk-input uk-width-9-10 uk-animation-slide-top-small"
               [placeholder]="fieldData.form.placeholder ? fieldData.form.placeholder : ''"
               [ngClass]="{'uk-form-danger': checkFormArrayValidity(i)}" (focusin)="focus(i)" (focusout)="focusOut(false, i)"
               (input)="updateBitSet(fieldData); unsavedChangesPrompt()">
        <div *ngIf="fieldAsFormArray().length > 1 && !readonly" class="">
          <span class="uk-sortable-handle uk-text-center uk-margin-small-left" uk-icon="icon: move"></span>
        </div>
        <a *ngIf="fieldAsFormArray().length > 1 && !readonly" (click)="remove(i); updateBitSet(fieldData); unsavedChangesPrompt()"
           class="remove-element uk-animation-slide-top-small uk-margin-left">
          <span class="material-icons">clear</span>
        </a>
      </div>
    </div>
    <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}"
         [innerHTML]="fieldData.form.suggestion.text">
    </div>

    <div *ngIf="!readonly" class="uk-width-9-10 uk-text-center uk-margin-top">
      <a class="add-new-element uk-flex uk-flex-middle uk-flex-center" (click)="push()">
        <span class="material-icons">add</span>Add &nbsp;<span [innerHTML]="fieldData.label.text"></span>
      </a>
    </div>
  </div>

  <ng-template #noMultiplicity>
    <input [formControlName]="fieldData.name" [id]="inputId" class="uk-input uk-width-9-10 uk-animation-scale-up"
           [placeholder]="fieldData.form.placeholder ? fieldData.form.placeholder : ''"
           (input)="updateBitSet(fieldData); unsavedChangesPrompt()" (focusin)="focus()" (focusout)="focusOut(false)"
           [ngClass]="{'uk-form-danger': checkFormValidity() }">
<!--      {{form.controls[fieldData.name].errors | json}}-->
    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}"
           [innerHTML]="fieldData.form.suggestion.text">
      </div>
    </div>
  </ng-template>

</form>
<!--<pre>{{form.value | json}}</pre>-->
