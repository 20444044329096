<div class="uk-section uk-section-default uk-preserve-color uk-padding-remove" uk-height-viewport="offset-top: true; offset-bottom: 20;">

  <div class="uk-grid uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l">
    <div class="uk-flex-last@m uk-flex-last@l">
      <img src="../../assets/images/1-2.png">
    </div>
    <div class="uk-first-column">
      <div class="uk-padding-large uk-margin-large-left@m uk-margin-large-left@l uk-margin-large-top">
        <h1>Monitoring the European Open Science Cloud (EOSC)</h1>
        <p class="big-text uk-margin-medium-top">The EOSC Observatory is <span class="uk-text-primary">a policy intelligence tool</span>
          being developed by the EOSC Future project for monitoring policies,  practices, and impacts related to the
          European Open Science Cloud (EOSC). It will support the EOSC community in tracking the implementation of EOSC
          and the policy makers in developing actionable policies.</p>
        <a class="uk-button uk-button-primary uk-margin-medium-top" [routerLink]="['/eoscreadiness']">Explore the EOSC Observatory</a>
      </div>

    </div>
  </div>
</div>

<div class="uk-section uk-section-default uk-preserve-color uk-margin-large-top">
  <div class="uk-container uk-container-xlarge">

    <div class="uk-grid uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l">
      <div>
        <h1>EOSC Monitoring Ecosystem</h1>
      </div>
    </div>

    <div class="uk-margin-medium-top">
      <p>
        The implementation and uptake of EOSC is being monitored in varying capacities and by various stakeholders at institutional,
        national, and European levels. Four key monitoring activities can be identified by the EOSC Steering Board, EOSC Association,
        and EOSC Ecosystem which are being implemented via data collection surveys
      </p>
    </div>

    <div class="uk-grid uk-child-width-1-4 uk-grid-match uk-margin-large-top uk-grid-medium">

      <div>
        <div class="uk-card uk-card-default uk-card-body">
          <a [routerLink]="['/eoscreadiness']">
            <div><img src="../../assets/images/icons/number1.svg" width="100"></div>
            <!--          <div class="big-number uk-text-secondary uk-text-bolder">1</div>-->
            <p class="primary-card-header">Indicators to Monitor EOSC Readiness</p>
            <p>The EOSC Steering Board has developed indicators to monitor the EOSC readiness of countries in Europe via an
              annual survey on National Contributions to EOSC</p>
          </a>
        </div>
      </div>

      <div>
        <div class="uk-card uk-card-default uk-card-body" style="opacity: 0.4">
          <div><img src="../../assets/images/icons/number2.svg" width="97"></div>
<!--          <div class="big-number uk-text-bolder">2</div>-->
          <p class="primary-card-header">Indicators to Monitor EOSC Partnership</p>
          <p>The EOSC Association has developed indicators to monitor the implementation of the EOSC Partnership by members
            via an annual survey on Key Performance Indicators</p>
        </div>
      </div>

      <div>
        <div class="uk-card uk-card-default uk-card-body" style="opacity: 0.4">
<!--          <div class="big-number uk-text-bolder">3</div>-->
          <div><img src="../../assets/images/icons/number3.svg" width="95"></div>
          <p class="primary-card-header">Contributions to EOSC Partnership</p>
          <p>The EOSC Association will monitor the financial and in-kind contributions to the EOSC Partnership by members
            via an annual survey on the Additional Activities Plan</p>
        </div>
      </div>

      <div>
        <div class="uk-card uk-card-default uk-card-body" style="opacity: 0.4">
<!--          <div class="big-number uk-text-secondary uk-text-bolder">4</div>-->
          <div><img src="../../assets/images/icons/number4.svg" width="90"></div>
          <p class="primary-card-header">Policies Relevant for EOSC Ecosystem</p>
          <p>Various stakeholders in the EOSC ecosystem (including projects and experts) are monitoring the implementation
            and uptake of EOSC via surveys on policies relevant for EOSC</p>
        </div>
      </div>

    </div>


  </div>
</div>

<div class="uk-section uk-section-default uk-preserve-color uk-margin-large-top">
  <div class="uk-container uk-container-xlarge">

    <div class="uk-grid uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-2@l">
      <div>
<!--        <div class="h1-subHeader">How it works</div>-->
        <h1>EOSC Observatory Dashboard</h1>
      </div>
    </div>

    <div class="uk-grid uk-child-width-1-2 uk-grid-match uk-margin-large-top">

      <div class="uk-padding-large">
        <img src="../../assets/images/ipad%20mockup.png">
      </div>

      <div class="uk-padding-xlarge">
        <p>The EOSC Observatory consists of an interactive dashboard for users to visualise and exploit data collected by
          surveys on the implementation and uptake of EOSC in Europe. The EOSC Observatory currently presents data on the
          EOSC readiness of countries from the survey on National Contributions to EOSC 2021 for the EOSC Steering Board
        </p>
      </div>

    </div>


  </div>
</div>

<div class="uk-section uk-section-default uk-preserve-color uk-text-center uk-margin-large-top">
  <div class="uk-container uk-container-xlarge">

    <div class="h1-subHeader-primary">Coming soon</div>
    <h1>EOSC Observatory Country Pages</h1>

    <div class="uk-grid uk-margin-medium-top">
      <div class="uk-width-1-4"></div>
      <div class="uk-width-1-2">
        <p>
          The EOSC Observatory will not only provide an interactive dashboard to exploit survey data collected by key EOSC
          stakeholders, but will also provide country pages that present the updated state-of-play, key statistics, and relevant
          contacts and links for EOSC for each country. The country pages are being developed by EOSC Future and are coming soon!
        </p>
      </div>
      <div class="uk-width-1-4"></div>
    </div>

    <div class="uk-margin-large-top">
      <img src="../../assets/images/2-2.png">
    </div>

  </div>
</div>

