<form [formGroup]="form" class="uk-margin-small-bottom {{fieldData.form.display.cssClasses}}">

  <div *ngIf="fieldData.form.description.text" class="uk-form-description uk-margin-top uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
       [innerHTML]="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
  </div>

  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity" [formArrayName]="fieldData.name">
    <div *ngFor="let input of fieldAsFormArray().controls; let i=index" class="uk-margin-bottom">
      <input [id]="fieldData.name" [formControlName]="i"
             class="uk-checkbox uk-width-9-10 uk-animation-slide-top-small" type="checkbox"
             [placeholder]="fieldData.form.placeholder ? fieldData.form.placeholder : ''"
             [ngClass]="{'uk-form-danger': checkFormArrayValidity(fieldData.name, i, this.editMode)}"
             (input)="updateBitSet(fieldData); unsavedChangesPrompt()">
      <a *ngIf="fieldAsFormArray().length > 1  && editMode"
         class="remove-element uk-padding-small uk-animation-slide-top-small"
         (click)="remove(fieldData.name, i); updateBitSet(fieldData); unsavedChangesPrompt()">
        <span class="material-icons">clear</span>
      </a>
    </div>
    <div class="uk-width-9-10"><i><small>{{fieldData.form.suggestion.text}}</small></i></div>

    <div *ngIf="editMode" class="uk-width-9-10 uk-text-center uk-margin-top">
      <a class="add-new-element uk-flex uk-flex-middle uk-flex-center"
         (click)="push(fieldData.name, fieldData.form.mandatory)">
        <span class="material-icons">add</span>Add &nbsp;<span [innerHTML]="fieldData.label.text"></span>
      </a>
    </div>
  </div>

  <ng-template #noMultiplicity>
    <label *ngIf="fieldData.label.text" class="uk-form-label {{fieldData.label.cssClasses}}" style="{{fieldData.label.style}}">
    <input [formControlName]="fieldData.name" [id]="fieldData.id" class="uk-checkbox" type="checkbox"
           (input)="updateBitSet(fieldData); unsavedChangesPrompt()"
           [ngClass]="{'uk-form-danger': checkFormValidity() }">

      <span class="uk-checkbox-label uk-margin-small-left">{{fieldData.label.text + (fieldData.form.mandatory ? ' (*)' : '')}}</span>
    </label>
    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}" [innerHTML]="fieldData.form.suggestion.text"></div>
    </div>
  </ng-template>

</form>
<!--<pre>{{form.value | json}}</pre>-->
