<div [formGroup]="form" class="uk-margin-small-bottom" [ngClass]="{'uk-hidden clear-style': hideField}">
  <div *ngIf="fieldData.label.text" class="uk-form-label uk-margin-bottom {{fieldData.label.cssClasses}}" [attr.for]="fieldData.name"
       [ngClass]="{'uk-subLabel': fieldData.form.dependsOn !== null}" style="{{fieldData.label.style}}" [innerHTML]="fieldData.label.text">
    {{fieldData.form.mandatory ? ' (*)' : ''}}
  </div>
  <div *ngIf="fieldData.form.description.text" class="uk-form-description uk-margin-bottom uk-width-9-10 {{fieldData.form.description.cssClasses}}"
       [innerHTML]="fieldData.form.description.text" style="{{fieldData.form.description.style}}">
  </div>
  <div *ngIf="fieldData.typeInfo.multiplicity; else noMultiplicity" [formArrayName]="fieldData.name">
    <!--To add multiplicity if needed-->
  </div>

  <ng-template #noMultiplicity>
    <dp-date-picker [formControlName]="fieldData.name" [id]="fieldData.name" class="uk-width-9-10"
                    [placeholder]="fieldData.form.placeholder ? fieldData.form.placeholder : ''"
                    (input)="updateBitSet(fieldData); unsavedChangesPrompt()" [config]="{format: 'YYYY-MM-DD'}"
                    [ngClass]="{'uk-form-danger': checkFormValidity() }"></dp-date-picker>
    <div *ngIf="fieldData.form.suggestion.text" class="uk-margin-top">
      <div class="uk-suggestion uk-width-9-10 {{fieldData.form.suggestion.cssClasses}}" style="{{fieldData.form.suggestion.style}}" [innerHTML]="fieldData.form.suggestion.text"></div>
    </div>
  </ng-template>
</div>
