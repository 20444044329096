<div class="uk-card uk-card-body uk-card-default uk-margin-medium-bottom">
  <div class="uk-grid">
    <div class="uk-width-2-3 uk-child-width-expand">
      <div class="uk-margin-bottom uk-form-controls">
        <input [(ngModel)]="field.label.text" class="uk-input uk-width-2-3" placeholder="Label">
      </div>
    </div>

    <div class="uk-width-1-3">
      <ng-select class="uk-width-1-1 uk-margin-bottom uk-form-controls" [searchable]="false" [clearable]="false"
                 [(ngModel)]="field.typeInfo.type" (change)="setValues()">
        <ng-option [value]="'string'" class="uk-flex uk-flex-middle"><span uk-icon="heart"></span> small Text</ng-option>
        <ng-option [value]="'largeText'" class="uk-flex uk-flex-middle"><span uk-icon="star"></span> largeText</ng-option>
        <ng-option [disabled]="true"><hr></ng-option>
        <ng-option [value]="'radio'"> radio</ng-option>
        <ng-option [value]="'select'"> dropdown</ng-option>
      </ng-select>
    </div>

    <div class="uk-width-1-1 uk-margin-bottom uk-form-controls uk-animation-scale-up" [ngClass]="{'uk-display-none' : !showDescription}">
      <ckeditor [editor]="editor" [config]="{placeholder: 'Give a short description for the field'}"
                [(ngModel)]="field.form.description.text">
      </ckeditor>
    </div>

    <div class="uk-width-1-1 uk-margin-bottom uk-form-controls uk-animation-scale-up" [ngClass]="{'uk-display-none' : !showSuggestion}">
      <ckeditor [editor]="editor" [config]="{placeholder: 'Give a short suggestion for the field'}"
                [(ngModel)]="field.form.suggestion.text">
      </ckeditor>
    </div>
    <div class="uk-width-1-1">
      <app-type-selector [typeInfo]="field.typeInfo"></app-type-selector>
    </div>

    <div class="uk-width-1-1 uk-form-controls">
      <hr>
      <div class="uk-float-right">
        <label><input type="checkbox" [(ngModel)]="field.form.mandatory" title="Make input required" class="uk-checkbox">
          Required </label>
        <label><input type="checkbox" [(ngModel)]="field.typeInfo.multiplicity"  class="uk-checkbox"
                      title="User may provide more than one answer to the given question"> Multiple </label>
        <label><input type="checkbox" (change)="showDescriptionField()" class="uk-checkbox"> Description </label>
        <label><input type="checkbox" (change)="showSuggestionField()" class="uk-checkbox"> Suggestion </label>
      </div>
    </div>
  </div>
</div>
